export default {
  SET_LINKS: (state, payload) => {
    state.links = payload;
  },
  SET_FORM_BUILDERS: (state, payload) => {
    state.formBuilders = payload;
  },
  SET_FORM_BUILDERS_PAGINATION: (state, payload) => {
    state.formBuildersPagination = payload;
  },
  SET_FORM_BUILDERS_LOADING: (state, payload) => {
    state.formBuildersLoading = payload;
  },
  SET_TEMPLATES: (state, payload) => {
    state.templates = payload;
  },
  SET_TEMPLATES_PAGINATION: (state, payload) => {
    state.templatesPagination = payload;
  },
  SET_TEMPLATES_LOADING: (state, payload) => {
    state.templatesLoading = payload;
  },
  SET_FORM_FLOW: (state, payload) => {
    state.formFlow = payload;
  },
  SET_LIST_VARIABLES: (state, payload) => {
    state.listVariables = payload;
  },
  SET_ENABLED: (state, payload) => {
    state.formFlow.enabled = !!payload;
  },
};
