const flow = {
  setup: {
    name: 'Fluxo',
    title: 'Vamos começar a criar seu fluxo',
    subtitle: 'Defina um nome e uma cor para você identificar este fluxo.',
    selectColor: 'Escolha uma cor para você identificar este fluxo:',
    labelName: 'Nome do fluxo',
    next: 'Avançar',
    back: 'Voltar',
    form: 'Formulário',
    forms: 'Formulários',
    template: 'Modelo',
    active: 'Ativar',
    activated: 'Ativado',
    copy: 'Copiar',
    copied: 'Copiado',
    close: 'Fechar',
    formSearch: 'Busque por um formulário',
    emptySearch:
      'Não encontramos resultados para a sua busca. {0} Que tal tentar um termo diferente?',
    formAlert: {
      description: 'Você precisa de pelo menos um formulário para continuar esse fluxo.',
      complement: 'Crie um formulário e, em seguida, volte para esta etapa para continuar.',
      new: 'Criar formulário',
    },
    formAlertTwo: {
      description: 'Você precisa de dois formulários para continuar esse fluxo.',
      complement: 'Crie um formulário e, em seguida, volte para esta etapa para continuar.',
      new: 'Criar formulário',
    },
    templateAlert: {
      description: 'Você precisa de um modelo para continuar esse fluxo.',
      complement: 'Crie um modelo e, em seguida, volte para esta etapa para continuar.',
      new: 'Criar Modelo',
    },
    stepForm: {
      name: 'Formulário',
      title: 'Selecione um formulário',
      empty: 'Não existe nenhum formulário em sua conta',
      new: 'Clique aqui para criar um formulário',
      modal: {
        title: 'O que deseja fazer em seguida?',
        option1: {
          title: 'Selecionar um segundo formulário',
          description:
            'Selecione esta opção quando seu documento precisar de informações provenientes de pessoas diferentes. Ex.: Comprador preenche dados cadastrais e Vendedor preenche informações do pedido.',
        },
        option2: {
          title: 'Avançar para a escolha do modelo',
          description: 'Selecione esta opção quando seu fluxo tiver apenas um formulário.',
        },
      },
      reviewFormFieldsTooltip:
        'Esse formulário não possui campos de email ou telefone obrigatórios',
    },
    stepFormTwo: {
      name: 'Formulário',
      title: 'Selecione o segundo formulário',
      empty: 'Não há outro formulário disponível na sua conta',
      new: 'Clique aqui para criar um formulário',
      settings: {
        newTitle: 'Preenchimento do 2º formulário',
        // TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED
        // TODO: Remove title locale
        title: 'Preenchimento do segundo formulário',
        afterFirstForm: 'Após o preenchimento do primeiro formulário',
        willBeSent: 'Será enviado para o e-mail ou WhatsApp que você definir',
        theSecondForm: 'O segundo formulário',
        fillSecondForm: 'Defina quem deverá preencher o segundo formulário',
        // TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED
        // TODO: Remove typeAnEmail locale
        typeAnEmail: 'Digite um e-mail',
        useAnEmail: 'Use um e-mail',
        selectFromFirstForm: 'Selecione do primeiro formulário',
        email: 'E-mail',
        phone: 'Telefone',
        emailOrWhatsapp:
          'E-mail ou WhatsApp do formulário (apenas campos obrigatórios estarão disponíveis)',
        noEmail: 'Não há e-mails para selecionar',
        noPhone: 'Não há telefones para selecionar',
        select: 'Selecione',
        messageInfo:
          'Mensagem que será enviada dentro do e-mail para preencher o segundo formulário. (Opcional)',
        addAnswersToEmail: 'Incluir neste e-mail as respostas do primeiro formulário.',
        tooltip: {
          typeAnEmail: 'Defina um e-mail para o envio do segundo formulário.',
          selectFromFirstForm:
            'Utilize um e-mail ou telefone digitado em um campo do primeiro formulário.',
        },
      },
    },
    stepTemplate: {
      name: 'Modelo',
      title: 'Selecione um modelo',
      new: 'Clique aqui para criar um modelo',
      search: 'Busque por um modelo',
      clickToEdit: 'Clique para editar o modelo de documento',
    },
    stepFields: {
      name: 'Conteúdo dos documentos',
      title: 'Conteúdo dos documentos',
      subtitle:
        'Selecione o campo do formulário correspondente ao campo do modelo para definir o conteúdo do documento.',
      templateField: 'Campo do modelo',
      formField: 'Campo do formulário',
    },
    stepFolder: {
      name: 'Nome dos documentos',
      title: 'Nome dos documentos',
      insertVariable: 'Inserir variável',
      warningLimitMsg:
        'Limite máximo de 255 caracteres. Evite usar variáveis com textos muito longos.',
      document: {
        subtitle: 'Configure um nome para os documentos',
        description: {
          initial: 'Insira ',
          variable: 'variáveis ',
          complement:
            'para que cada documento gerado tenha nome diferente. Elas serão substituídas pelos conteúdos dos campos do formulário (apenas campos obrigatórios estarão disponíveis).',
        },
        popover: {
          initial:
            'Uma variável receberá um dado de um campo do formulário, as variáveis disponíveis são as mesmas do modelo selecionado neste fluxo, e serão exibidas na cor amarela entre chaves {{}}.',
          complement:
            'Insira variáveis para que cada documento gerado por este fluxo possua um nome diferente, isto ajudará a identificar o documento.',
          variable: 'Veja um exemplo usando variáveis:',
          example: 'Desta forma o nome do documento ficaria:',
        },
      },
      folder: {
        subtitle: 'Configure em qual pasta os documentos serão gerados',
        description: {
          initial: 'Insira ',
          variable: 'variáveis ',
          complement:
            'se quiser que cada documento seja gerado em pastas com nomes diferentes. Para definir ',
          subfolder: 'sub-pastas ',
          final:
            'adicione barras “/” entre cada nome (apenas campos obrigatórios estarão disponíveis).',
        },
        variable: {
          initial:
            'Uma variável receberá um dado de um campo do formulário, as variáveis disponíveis são as mesmas do modelo selecionado neste fluxo e serão exibidas na cor amarela entre chaves {{}}.',
          complement:
            'Insira variáveis para que cada documento gerado por este fluxo seja armazenado em pastas com conteúdos dos campos do formulário.',
          example: 'Exemplo usando variável:',
          name: 'Desta forma um exemplo de como ficaria o nome da pasta:',
        },
        subpath: {
          initial:
            'Para o documento ser gerado dentro de sub-pastas, você pode digitar separando por uma barra /.',
          variable: 'Veja um exemplo:',
          example:
            'Dessa forma o documento será gerado, por exemplo, dentro da pasta 2020 que está dentro da pasta Contratos.',
        },
        chooseFolder: 'Escolher pasta',
      },
    },
    stepSigners: {
      name: 'Lista de assinatura',
      title: 'Lista de assinatura',
      subtitle: 'Adicione signatários para que eles assinem o documento que será gerado.',
      empty: 'Nenhum signatário adicionado.',
      newSigner: 'Signatário novo',
      newSignerForm: 'Signatário do formulário',
      newSignerContact: 'Signatário da agenda',
      myselfAsSigner: 'Eu vou assinar',
      selfSignerTooltip: 'Adicione-se com facilidade ao processo de assinatura.',
      selfSignerTitle: 'Precisa assinar também?',
      iGot: 'Entendi',
      orderSigners: 'Ordenar assinaturas',
      orderingInfo: 'Opção disponível para o envio de 1 documento',
      envelope: {
        unavailable: 'Indisponível para este documento',
      },
      blockModal: {
        contacts: {
          title: 'Não é possível adicionar signatários',
          description:
            'Primeiro exclua os signatários com assinaturas recusadas do documento para então adicionar signatários da agenda.',
        },
        addSigner: {
          title: 'Não é possível adicionar signatários',
          description:
            'Primeiro exclua os signatários com assinaturas recusadas do documento para então adicionar novos signatários.',
        },
        order: {
          title: 'Não é possível ordenar as assinaturas',
          description:
            'Primeiro exclua os signatários com assinaturas recusadas do documento para então ordenar as assinaturas.',
        },
      },
      reviewFormFieldsModal: {
        title: 'Erro ao incluir signatário do formulário',
        text: 'Isso pode ocorrer por dois motivos',
        missingFields: 'Não localizamos os campos {0} e {1} (para assinatura via WhatsApp).',
        requiredFields: 'Os campos estão no formulário, mas não constam como obrigatórios.',
        email: 'E-mail do signatário',
        phoneNumber: 'Telefone/WhatsApp',
        helpLinkText: 'Saiba mais sobre adição de signatário variável',
        formInUseTooltip: 'Já está em uso por outro fluxo.',
        close: 'Fechar',
        instructions: 'Clique no link do formulário abaixo para ajustar.',
      },
      contactTooltip: {
        title: 'Quer assinar este envelope?',
        // TOGGLE_ENVELOPE_SETUP_SCOPE
        // TODO: Remove titleBatch
        titleBatch: 'Quer assinar este documento?',
        content:
          'Suas informações já estão na agenda de contatos! É só clicar em {0} para se adicionar.',
        signerFromContacts: 'Signatário da agenda',
        action: 'Entendi',
      },
    },
    stepSettings: {
      name: 'Configurações',
      title: 'Configurações',
      subtitle: 'Configurações que serão aplicadas aos documentos gerados.',
      complianceProcess: 'Aprovação das respostas do fluxo',
      complianceProcessInfo:
        'Caso ativado, o fluxo ficará aguardando a aprovação de um operador antes de o documento ser gerado e enviado para assinatura.',
      complianceYes:
        'Ativado. Aguardar a aprovação de um operador para gerar o documento e enviar para assinatura.',
      complianceNo: 'Desativado. O documento será enviado automaticamente para assinatura.',
      notificationMembers: 'Defina quem receberá notificação via e-mail para aprovar os fluxos',
      deadlineDate: 'Data limite de assinatura',
      dateValue: '{day} dia | {day} dias',
      deadlineDateInfo:
        'Selecione quantos dias os documentos estarão disponíveis para serem assinados.',
      autoReminders: 'Lembretes automáticos',
      autoRemindersInfo:
        'Envio de até 3 lembretes por e-mail para signatários que ainda não assinaram.',
      sendReminders: 'Enviar lembretes automaticamente a cada',
      documentLanguage: 'Idioma',
      documentLanguageInfo:
        'A página de assinatura e os e-mails serão exibidos no idioma selecionado.',
      closeDocument: 'Finalização dos documentos',
      closeDocumentInfo: 'Escolha de que forma o documento será finalizado',
      autoClose: 'Após a última assinatura o documento será finalizado automaticamente.',
      manualClose:
        'Manualmente. Você terá que finalizar o documento manualmente, caso contrário, ele será finalizado na data limite de assinatura.',
      english: 'Inglês (EUA)',
      portuguese: 'Português',
      tooltip: {
        complianceProcess:
          'Revise as respostas dos formulários na página de aprovação e envie para assinatura apenas após aprovado.',
        deadlineDate:
          'Quando a data limite for atingida, o documento será finalizado automaticamente. Caso não conste nenhuma assinatura, ele será cancelado.',
        documentLanguage:
          'Opção para troca do idioma mostrado no momento da assinatura do documento.',
        closeDocument: 'Escolha quando o documento será finalizado.',
      },
      flowOperator: {
        title: 'Operador do fluxo',
        description:
          'O e-mail deste operador será exibido no Log de todos os documentos assinados que foram gerados por este fluxo. Usuários tipo Observador não serão listados.',
      },
    },
    stepMessage: {
      name: 'Mensagem',
      title: 'Mensagem',
      subtitle:
        'Mensagem que será enviada para todos os signatários ao receberem o documento para assinar.',
      label: 'Mensagem (Opcional)',
      placeholder: 'Digite sua mensagem (Opcional)',
    },
    stepActive: {
      allReady: 'Tudo pronto',
      templateGeneratedWithSuccess:
        'Seu modelo foi criado com sucesso! Agora você pode enviar o link abaixo para a pessoa que deve preencher os campos do documento.',
      afterFillANewDocument:
        'Após o preenchimento, um novo documento será gerado e enviado para assinatura automaticamente.',
      name: 'Ativação',
      title: 'Ativação',
      subtitle:
        'Ao ativar o fluxo, um novo documento será gerado a partir do preenchimento do formulário. Enquanto ativo, você não poderá realizar alterações no formulário e no modelo que estão sendo utilizados por este fluxo.',
      activatedInfo:
        'Envie este link para a pessoa que deve preencher o formulário. Após o preenchimento, um novo documento será gerado e enviado para assinatura automaticamente.',
      activeError:
        'Oops! Há uma inconsistência neste Autoflow. Revise todos os passos e tente ativar novamente.',
      createAnotherFlowError: 'Oops! Não foi possível criar um novo fluxo.',
    },
    upload: {
      template: 'Documento modelo',
      notice: 'Para a criação do seu documento modelo, só é aceito um documento por vez.',
      dragAndDrop: 'Arrastar e soltar aqui ou',
      error: {
        format: 'Formato de arquivo inválido. Selecione um arquivo .docx ou .pdf.',
        general: 'Houve um erro com seu documento. Faça upload de outro documento.',
        multipleDocs: 'Você só pode enviar um documento por vez.',
        requiredDoc: 'Selecione ao menos um arquivo.',
      },
    },
    selectTemplate: {
      title: 'Selecione um modelo',
      subtitle: 'Faça o upload de um documento modelo ou selecione um já existente.',
      uploadTemplate: 'Upload de documento modelo',
      or: 'ou',
    },
    settings: {
      title: 'Vamos configurar seu fluxo',
      flowName: 'Nome do fluxo',
      error:
        'Oops! Há uma inconsistência na configuração do fluxo. Revise todos os campos e tente ativar novamente.',
    },
    docsSettings: {
      title: 'Configurações dos documentos',
    },
  },
};

export default flow;
