import { useI18n } from '@/locales';

const { t } = useI18n();

const HOVER_INFO = {
  token: {
    whatsapp: {
      text: t('signer.infoStep.communicateBy.warning'),
      link: 'https://ajuda.clicksign.com/article/908-quanto-custa-enviar-um-documento-para-assinatura-via-whatsapp',
    },
    sms: {
      text: t('signer.infoStep.envelope.authsModal.smsWarning'),
      link: 'https://ajuda.clicksign.com/article/1026-quanto-custa-envio-token-sms',
    },
    whatsappBlocked: {
      text: t('signer.infoStep.envelope.authsModal.whatsappBlocked'),
    },
  },
  selfie: {
    text: t('signer.infoStep.envelope.authsModal.selfieBlocked'),
  },
  facialBiometrics: {
    text: t('signer.infoStep.envelope.authsModal.facialBiometricsBlocked'),
  },
  liveness: {
    text: t('signer.infoStep.envelope.authsModal.livenessBlocked'),
  },
};

export default { HOVER_INFO };
