<template>
  <div :class="[$style.spinner, $style[size]]" />
</template>

<script>
export default {
  name: 'BaseSpinner',
  props: {
    size: {
      type: String,
      default: 'medium',
    },
  },
};
</script>

<style lang="scss" module>
.spinner {
  position: relative;
  margin: 0 auto;
  font-size: 10px;
  border: 5px solid var(--color-neutral-500);
  border-color: var(--color-neutral-500);
  border-style: solid;
  border-left-color: var(--color-neutral-1000);
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  &.medium {
    width: 48px;
    height: 48px;
    border-width: 5px;
  }

  &.small {
    width: 24px;
    height: 24px;
    border-width: 4px;
  }
}

@media (min-width: 768px) {
  .spinner {
    &.medium {
      width: 80px;
      height: 80px;
      border-width: 9px;
    }

    &.small {
      width: 32px;
      height: 32px;
      border-width: 6px;
    }
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
