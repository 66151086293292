const billing = {
  tip: {
    text: 'Apenas os administradores da conta e usuários com perfis financeiros, podem visualizar esta aba.',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove textOld when scope > 3
    textOld: 'O administrador da conta pode conceder acesso à aba cobranças',
    link: 'Edite para habilitar',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove linkOld when scope > 3
    linkOld: 'Edite um usuário',
    description: 'o acesso de outros usuários',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove descriptionOld when scope > 3
    descriptionOld: 'da conta para habilitar o acesso',
    disabledLink: 'Disponível só para administradores',
    disabledAlert: 'Aba disponível {0}. Somente administradores da conta podem fazer alterações.',
    onlyForView: 'apenas para visualização',
  },
  vindiErrors: {
    title: 'Nada para mostrar, por enquanto.',
    message: {
      processing: 'Ainda estamos processando as informações desta aba.',
      waiting: 'Aguarde alguns minutos e tente novamente ou ',
      talkToSupport: 'fale com nosso suporte',
    },
    button: {
      back: 'Voltar',
    },
  },
  products: {
    sms: 'Token via SMS',
    whatsapp: 'Token via WhatsApp',
    document: 'Documentos assinados',
    acceptanceTermWhatsapp: 'Aceite via WhatsApp',
    timestamp: 'Carimbo do tempo',
    biometricsAuthentication: 'Biometria facial',
    dynamicSelfieAuthentication: 'Selfie dinâmica',
  },
  negotiation: {
    products: {
      additionalDocuments: 'Documentos adicionais',
      sms: 'SMS',
      whatsapp: 'WhatsApp',
      document: 'Documentos assinados',
      acceptanceTermWhatsapp: 'Aceite via WhatsApp',
      timestamp: 'Carimbo do tempo',
      biometricsAuthentication: 'Biometria facial',
      dynamicSelfieAuthentication: 'Selfie dinâmica',
    },
  },
  chargeInfo: {
    title: 'Dados de pagamento',
    cpf: 'CPF: {cpf}',
    cnpj: 'CNPJ: {cnpj}',
    name: 'Nome: {name}',
    tradingName: 'Razão Social: {tradingName}',
    email: 'E-mail de cobrança: {email}',
    cep: 'CEP: {cep}',
    change: 'Alterar dados',
  },
  dueDay: {
    info: 'Vencimento da fatura no dia {dueDay}',
    change: 'Alterar vencimento',
  },
  paymentProfile: {
    title: 'Cobrança',
    label: '{creditCardFlag} - Final **{creditCardLastFourDigits}',
    change: 'Alterar',
    bankSlip: 'Boleto',
    changePaymentMethod: 'Alterar forma de pagamento',
    tooltip: {
      title: 'No cartão, o pagamento é confirmado na mesma hora',
      description: 'Adicione um cartão de crédito para evitar atrasos e bloqueio da conta.',
      ignore: 'Ignorar',
      addCreditCard: 'Adicionar',
    },
  },
  plan: {
    title: 'Meu plano',
    helpLink: 'Entenda seu consumo',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove titleOld when scope > 3
    titleOld: 'Plano',
    changePlan: 'Alterar plano',
    stepperPaths: {
      title: 'Plano e cobrança',
      choose: 'Escolha',
      change: 'Alterar forma de pagamento',
      add: 'Adicionar',
      confirm: 'Confirmação',
    },
    extraResources: 'Recursos extras',
    consumption: {
      understandingYourConsumption: 'Entenda seu consumo',
      current: 'Consumo do plano',
      title: 'Consumo atual',
      period: 'Período: {start} à {end}.',
      description: 'Entenda',
      link: 'como o consumo é contabilizado.',
      monthly: 'mensal',
      yearly: 'anual',
      accounting: 'Contabilização {0}. Período atual: {1} a {2}.',
    },
    accountingYearly: {
      users: {
        total: '{0} usuários utilizados',
        description: 'Usuários ilimitados',
      },
      documents: {
        total: '{0} documentos utilizados',
        description: '{0} documentos contratados',
      },
    },
    list: {
      signature: {
        title: 'Escolha seu novo plano Clicksign Assinatura',
        description: 'Escolha, troque ou cancele seu plano a qualquer momento, sem multas',
      },
      custom: {
        title: 'Planos personalizados',
        description:
          'Conheça nossas soluções personalizadas para melhorar o desempenho do seu negócio',
      },
      tableTitle: 'Compare os planos',
    },
    confirmChange: {
      title: 'Confirme a alteração de plano',
      current: 'Plano atual',
      new: 'Plano novo',
      consumption: {
        title: 'Seu consumo',
        usage: 'Você usou {0} dos {1} dias contratados no plano atual.',
        link: 'Entenda como funciona nosso cálculo.',
      },
    },
  },
  settings: {
    title: 'Definições de cobrança',
    changeInfo: 'Alterar dados de cobrança',
    changeDueDay: 'Alterar dia de vencimento',
    changePaymentMethod: 'Alterar forma de pagamento',
  },
  payment: {
    title: 'Alterar forma de pagamento',
    header: 'Formas de pagamento cadastradas',
    card: 'Cartão {name} final ({number})',
    expired: 'Expirado',
    bankSlip: 'Boleto',
    same: 'Selecione um método de pagamento diferente do atual',
    changeSuccess: 'Forma de pagamento alterada com sucesso',
    deleteModal: {
      title: 'Excluir cartão',
      description: 'Tem certeza que deseja excluir o cartão final ({0})?',
      actions: {
        delete: 'Excluir',
        cancel: 'Cancelar',
      },
    },
    addCard: {
      title: 'Adicionar cartão',
      description: 'Informe os dados de pagamento',
      cardNumber: 'Número do cartão',
      cardName: 'Nome impresso no cartão',
      expireDate: 'Data de validade',
      cvv: 'CVV',
      cvvTooltip: 'Código de 3 dígitos do verso do cartão',
      actions: {
        add: 'Adicionar',
      },
      success: 'Cartão adicionado com sucesso',
      error: 'Algo deu errado, entre em contato com a',
    },
    deleteCard: {
      success: 'Cartão excluído com sucesso',
    },
    confirm: {
      title: 'Confirme a nova forma de pagamento',
      description: 'A alteração será aplicada somente no próximo período de contabilização.',
      review: 'Revise as informações antes de confirmar',
      methods: {
        current: 'Forma de pagamento atual',
        new: 'Nova forma de pagamento',
      },
    },
    actions: {
      addCard: 'Adicionar cartão',
      save: 'Salvar',
    },
  },
  cancelPlan: {
    title: 'Solicitar cancelamento',
  },
  cancelAccount: {
    title: 'Cancelar conta',
    request: 'Solicitar cancelamento',
  },
  info: {
    form: {
      info: 'Dados do cliente',
      address: 'Endereço de cobrança',
    },
  },
  trial: {
    period: 'Conta em período de teste até {date}',
    remaining: {
      title: 'Saldo restante do período gratuito:',
      documents: '{documents} envios de documentos',
      acceptance: '{acceptance} envios de aceite',
      noCharge: 'Nenhuma cobrança automática será gerada.',
      charge: 'Saldo disponível apenas durante o período de teste.',
    },
    // TOGGLE_MIDAS_SCOPE: 3
    // TODO: remove title
    title: 'Conta em período de teste',
    link: 'Contrate um plano',
    description:
      'Você poderá aproveitar os {0} envios gratuitos do seu período de teste até {1}. Ao final desse período, as funcionalidades serão bloqueadas. Não se preocupe, {2}',
    emphasis: 'nenhuma cobrança automática será gerada.',
    ending: 'O seu período de experiência {2}',
    endingEmphasis: 'finalizou.',
    hiring:
      'Contrate um plano para seguir enviando documentos de forma simples, rápida e com validade jurídica.',
  },
  changeDueDay: {
    pickADay: {
      title: 'Escolha um novo dia de vencimento',
      period: 'Período de contabilização atual: {start} a {end}.',
      description:
        'Cobranças que vencem sábado, domingo ou feriado serão prorrogadas para o primeiro dia útil disponível.',
      placeholder: 'Selecione uma data...',
      datePicker: 'Dia ',
    },
    confirmDate: {
      title: 'Confirme as alterações',
      current: 'Data do pagamento atual',
      new: 'Nova data de pagamento ',
      everyDay: 'Todo dia {0}',
      description: 'A alteração será aplicada somente no próximo período de contabilização.',
      alert: 'Revise as informações antes de confirmar.',
      success: 'Dia do vencimento alterado com sucesso',
      error: 'Limite de alteração excedido',
      errorText: 'Limite de alteração excedido, fale com nossa',
      linkText: 'central de suporte',
      helpCenter: 'central de ajuda',
    },
  },
  billingAlert: {
    pending: {
      description: 'Sua fatura já está disponível para pagamento. {0}',
      action: 'Confira na aba Plano e Cobrança, em Configurações.',
    },
    overdueToday: {
      description: 'Sua fatura vence hoje. {0} para garantir seu acesso à conta.',
      action: 'Realize o pagamento',
    },
    overdue: {
      desktopTitle: 'Pagamento não identificado. Seu acesso será bloqueado em {days} dias. ',
      mobileTitle: 'Pagamento não identificado.',
      description: 'Se o pagamento já foi feito, {0}.',
      action: 'clique aqui para enviar seu comprovante',
      remainingDaysToBlock: '{days} dias | {days} dia | {days} dias',
    },
    action: 'Pagar fatura',
  },
  trustWindowAlert: {
    pending: {
      title: 'Estamos verificando o pagamento da sua fatura',
      description: 'Enquanto isso, você pode seguir usando a Clicksign!',
    },
    pendingMobile: {
      title: 'Verificando o pagamento da sua fatura',
      description: 'Enquanto isso, continue usando a Clicksign!',
    },
    lastDay: {
      title: 'Pagamento ainda não identificado. Seu acesso será bloqueado em {date}.',
      action: 'Pagar fatura',
      description: 'Se o pagamento já foi feito, {0}.',
      paymentVoucher: 'clique aqui para enviar seu comprovante',
    },
    lastDayMobile: {
      title: 'Pagamento não identificado',
    },
  },
  // TOGGLE_ACCOUNT_REACTIVATION_TRUST_WINDOW_ENABLED
  // TODO: remove the whole locale object below
  trustWindowAlertOld: {
    pending: {
      title: 'Estamos verificando o pagamento da sua fatura',
      description: 'Enquanto isso, você pode seguir usando a Clicksign!',
    },
    pendingMobile: {
      title: 'Verificando o pagamento da sua fatura',
      description: 'Enquanto isso, continue usando a Clicksign!',
    },
    lastDay: {
      title: 'Ainda não conseguimos identificar o pagamento',
      description: 'Se o pagamento já foi feito, {0}.',
      paymentVoucher: 'clique aqui para enviar seu comprovante',
    },
    lastDayMobile: {
      title: 'Ainda não identificamos o pagamento',
      description: '{0}.',
      paymentVoucher: 'Clique aqui para enviar seu comprovante',
    },
  },
  invoiceOverdueModal: {
    title: 'Pague a última fatura para garantir seu acesso',
    description: 'Se o pagamento já foi feito, desconsidere a mensagem.',
    sendVoucher: 'clique aqui para enviar o comprovante.',
    payInvoice: 'Pagar fatura',
  },
  invoiceDueTodayModal: {
    title: 'A fatura da sua conta vence hoje',
    description:
      'Dica: para manter o pagamento sempre em dia e evitar o bloqueio do seu acesso, pague com cartão de crédito.',
    seeInvoice: 'Ver fatura',
    addCreditCard: 'Cadastrar cartão',
  },
};

export default billing;
