import templateModule from '@flow/locales/pt_BR/template';
import flowModule from '@flow/locales/pt_BR/formFlow';
import disabledFlow from '@flow/locales/pt_BR/disabledFlow';
import spreadsheetFlow from '@flow/locales/pt_BR/spreadsheetFlow';
import pendingAnswers from '@flow/locales/pt_BR/pendingAnswers';
import compliance from '@flow/locales/pt_BR/compliance';
import fieldCreator from '@flow/locales/pt_BR/fieldCreator';
import tracking from '@tracking/locales/pt_BR/tracking';
import nodes from '@document/locales/pt_BR/nodes';
import documentShow from '@document/locales/pt_BR/documentShow';
import signer from '@document/locales/pt_BR/signer';
import draft from '@document/locales/pt_BR/draft';
import envelope from '@envelope/locales/pt_BR';
import downloads from '@document/locales/pt_BR/downloads';
import accountModule from '@account/locales/pt_BR/account';
import subscription from '@account/locales/pt_BR/subscription';
import operators from '@operators/locales/pt_BR/operators';
import membership from '@account/locales/pt_BR/membership';
import group from '@account/locales/pt_BR/group';
import billing from '@account/locales/pt_BR/billing';
import audit from '@account/locales/pt_BR/audit';
import betaProgram from '@/modules/account/locales/pt_BR/beta-program';
import report from '@report/locales/pt_BR/report';
import auth from '@auth/locales/pt_BR/auth';
import layout from '@base/locales/pt_BR/layout';
import documentPreview from '@base/locales/pt_BR/documentPreview';
import dashboard from '@dashboard/locales/pt_BR/dashboard';
import dashboardDetails from '@dashboard/locales/pt_BR/dashboardDetails';
import acceptanceTerm from '@acceptance-term/locales/pt_BR/acceptance-term';
import signerArea from '@signer-area/locales/pt_BR/signer-area';
import formBuilder from '@/modules/flow/locales/pt_BR/formBuilder';
import signature from '@widget/locales/pt_BR/signature';
import workspaces from '@workspaces/locales/pt_BR';
import branding from '@account/locales/pt_BR/branding';
import chat from '@ai/locales/pt_BR/chat';
import general from './general';
import account from './account';
import base from './base';
import document from './document';
import modal from './modal';
import flow from './flow';
import template from './template';
import consumption from './consumption';
import validation from './validation';
import validator from './validator';

const ptBr = {
  general,
  account,
  base,
  document,
  documentShow,
  modal,
  flow,
  template,
  consumption,
  report,
  auth,
  validation,
  validator,
  templateModule,
  flowModule,
  disabledFlow,
  spreadsheetFlow,
  pendingAnswers,
  tracking,
  formBuilder,
  accountModule,
  nodes,
  signer,
  draft,
  envelope,
  downloads,
  operators,
  subscription,
  membership,
  billing,
  layout,
  dashboard,
  dashboardDetails,
  acceptanceTerm,
  signerArea,
  audit,
  betaProgram,
  documentPreview,
  signature,
  workspaces,
  group,
  compliance,
  branding,
  chat,
  fieldCreator,
};

export default ptBr;
