const nodes = {
  rootFolder: 'Documentos',
  list: {
    signers:
      'Nenhum signatário adicionado | {count}/{length} Assinatura | {count}/{length} Assinaturas',
    documents: '{count} Documento | {count} Documentos',
    actions: {
      selectAll: 'Selecionar todos da página',
      downloadOriginal: 'Baixar documento original',
      downloadSigned: 'Baixar documento assinado',
      downloadOriginalandSigned: 'Baixar documento original e assinado',
      download: 'Baixar',
      move: 'Mover',
      bringBack: 'Restaurar',
      hardDelete: 'Excluir definitivamente',
      permissions: 'Permissões',
      new: 'Nova pasta',
      edit: 'Renomear',
      delete: 'Excluir',
      cancel: 'Cancelar',
      menu: 'Ações',
    },
    statuses: {
      canceled: 'Cancelado',
      closed: 'Finalizado',
      running: 'Em processo',
    },
    signatureStatus: {
      refused: 'Assinatura recusada',
    },
    document: {
      path: 'em {0}',
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: remove pathOld
      pathOld: 'em Documentos{0}',
      default: 'em Todos',
    },
    signatures: {
      showMore: 'Ver mais signatários',
      showLess: 'Ver menos signatários',
    },
    at: 'Em {0}',
    until: 'Até {0}',
    createdAt: 'Criado em',
    empty: {
      title: 'Adicione e envie documentos para assinatura.',
      description: 'Baixe um {link} e adicione para testar.',
      emptyFolder: 'Pasta vazia.',
      document: {
        url: '/docs/Contrato de Prestação de Serviço - Exemplo.pdf',
        example: 'documento exemplo',
        name: 'Contrato de Prestação de Serviço - Exemplo.pdf',
        new: 'Adicionar Documentos',
        move: 'Mova um documento para esta pasta selecionando um documento e clicando no botão mover.',
      },
    },
  },
  search: {
    title: 'resultado para | resultados para',
    appliedFilters: 'os filtros aplicados',
    empty: {
      title: 'Nenhum resultado encontrado na busca',
      description: 'Experimente alterar sua pesquisa ou filtro para achar o que está procurando',
      allDocuments: 'Ver todos os documentos',
    },
  },
  empty: {
    title: 'Adicione e envie documentos para assinatura.',
    description: 'Baixe um {link} e adicione para testar.',
    documentExample: 'documento exemplo',
    documentName: 'Contrato de Prestação de Serviço - Exemplo.pdf',
    newDocuments: 'Adicionar Documentos',
    emptyFolder: 'Pasta vazia.',
    moveDocument:
      'Mova um documento para esta pasta selecionando um documento e clicando no botão mover.',
  },
  running: {
    title: 'Em processo',
    empty: {
      documents: {
        title: 'Documentos em processo serão exibidos aqui',
      },
      envelopes: {
        title: 'Envelopes em processo serão exibidos aqui',
      },
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: When toggle is true remove title locale
      title: 'Após enviar um documento para assinatura, você poderá vê-lo aqui.',
      description: 'Acompanhe os status das assinaturas em andamento',
    },
    refusedEmpty: {
      documents: {
        title: 'Documentos com assinaturas recusadas serão exibidos aqui.',
      },
      envelopes: {
        title: 'Envelopes com assinaturas recusadas serão exibidos aqui.',
      },
      title: 'Documentos com assinaturas recusadas serão exibidos aqui.',
    },
  },
  closed: {
    title: 'Finalizados',
    empty: {
      documents: {
        title: 'Documentos finalizados serão exibidos aqui',
      },
      envelopes: {
        title: 'Envelopes finalizados serão exibidos aqui',
      },
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: When toggle is true remove title locale
      title: 'Envie documentos para assinatura, após finalizados eles serão exibidos aqui.',
      description: 'Confira quais assinaturas foram concluídas com sucesso',
    },
  },
  canceled: {
    title: 'Cancelados',
    empty: {
      documents: {
        title: 'Documentos cancelados serão exibidos aqui',
      },
      envelopes: {
        title: 'Envelopes cancelados serão exibidos aqui',
      },
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: When toggle is true remove title locale
      title: 'Documentos cancelados são exibidos aqui.',
      description: 'Apenas documentos em processo podem ser cancelados.',
    },
  },
  trashBin: {
    deleteInfo: 'Tem certeza que deseja excluir definitivamente este(s) item(ns)',
    notifications: {
      restored: 'Item restaurado com sucesso | Itens restaurados com sucesso',
      deleted: 'Sucesso! Item excluído definitivamente | Sucesso! Itens excluídos definitivamente',
    },
    empty: {
      title: 'Documentos ou pastas excluídas serão exibidas aqui.',
      description: 'Para excluir um documento, ele precisa estar cancelado ou finalizado.',
    },
    blockedEmpty: {
      title: 'Apenas administradores da conta tem acesso aos documentos da lixeira.',
      description:
        'Caso necessite verificar um documento excluído, entre em contato com um administrador da conta.',
    },
  },
  modalDelete: {
    header: 'Excluir Item | Excluir Itens',
    info: 'Tem certeza que deseja excluir este item? | Tem certeza que deseja excluir estes itens?',
    disallowedInfo:
      'Há documento(s) em processo selecionados. {0}, você precisa finalizar ou cancelar para excluir.',
    strongInfo: 'Não é possível excluir itens que estão em processo',
    notifications: {
      deleted: 'Item movido para lixeira com sucesso | Itens movidos para lixeira com sucesso',
    },
  },
  modalCancel: {
    header: 'Cancelar documento? | Cancelar documentos?',
    info: 'A ação invalidará as assinaturas já efetuadas.',
    confirmation: 'Esta é uma ação irreversível.',
    notifications: {
      success: 'Documento cancelado com sucesso | Documentos cancelados com sucesso',
    },
  },
  modalFolderForm: {
    placeholder: 'Digite o nome da pasta',
    editFolder: {
      header: 'Renomear Pasta',
      action: 'Renomear',
      notifications: {
        success: 'Pasta renomeada com sucesso',
      },
    },
    create: {
      header: 'Criar uma nova pasta',
      action: 'Criar',
      notifications: {
        success: 'Pasta criada com sucesso',
      },
    },
  },
  modalSelectFolder: {
    newFolder: 'Criar pasta',
    confirm: 'Confirmar seleção',
  },
  modalEditEnvelope: {
    header: 'Renomear envelope',
    placeholder: 'Escreva aqui',
    rename: 'Renomear',
    notifications: {
      edited: 'Envelope renomeado com sucesso',
    },
  },
  modalMoveNodes: {
    title: 'Mover itens para ...',
    notifications: {
      moved: 'Item movido com sucesso | Itens movidos com sucesso',
    },
  },
  modalDownloadOptions: {
    title: 'Quais documentos deseja baixar?',
    options: {
      signedDocuments: 'Assinados',
      originalDocuments: 'Originais',
      originalAndSignedDocuments: 'Originais e assinados',
    },
    notice: 'Os documentos que ainda estiverem em processo de assinatura não serão baixados',
  },
  modalFolderPermissions: {
    title: 'Permissões de acesso da pasta',
    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: remove titleOld, publicOld and privateOld
    titleOld: 'Definir permissões de acesso da pasta ',
    publicOld: 'Acesso para todos os usuários da conta',
    privateOld: 'Acesso apenas para usuários selecionados',
    public: 'Todos os usuários da conta',
    private: 'Apenas usuários selecionados',
    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: remove selectAccessTypeOld
    selectAccessTypeOld: 'Selecione o tipo de acesso da pasta',
    selectAccessType: 'Selecione quem poderá acessar a pasta',
    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: remove selectMembersOld
    selectMembersOld: 'Selecione os usuários que terão acesso à pasta',
    selectMembers: 'Usuários',
    selectGroups: 'Grupos',
    submitSuccess: 'Definições de acesso salvas.',
    admin: 'Administrador',
    adminInfo: 'Administradores da conta sempre terão acesso as pastas por padrão',
    selectAll: 'Selecionar todos',
  },
  message: {
    messageTemplates: 'Mensagens salvas',
    placeholder: 'Digite aqui.',
    label: 'Mensagem',
  },
  original: 'Original',
  signed: 'Assinado',
  all: 'Todos',
  orderField: {
    placeholder: 'Ordenar por',
    items: {
      alphabeticOrder: 'Ordem alfabética',
      createdAtDesc: 'Enviados mais recentes',
      createdAtAsc: 'Enviados mais antigos',
      deadlineAt: 'Perto da data limite de assinatura',
      finishedAt: 'Finalizados mais recentes',
      canceledAt: 'Cancelados mais recentes',
      deletedAt: 'Excluídos mais recentes',
    },
  },
  filter: {
    seeDocuments: 'Ver documentos',
    seeEnvelopes: 'Ver envelopes',
    seeRefused: 'Ver assinaturas recusadas',
    // TOGGLE_ENVELOPE_SETUP_SCOPE
    // TODO: remove showAll and onlyRefused
    showAll: 'Ver tudo',
    onlyRefused: 'Ver apenas assinaturas recusadas',
  },
  refused: '(assinatura recusada)',
  blockFinishDocumentModal: {
    title: 'Não é possível finalizar o documento',
    description:
      'Primeiro exclua os signatários com assinaturas recusadas do documento para então finalizar.',
  },
};

export default nodes;
