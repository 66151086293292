export default {
  AMOUNT: [
    'Até 5 documentos',
    'Entre 6 e 20 documentos',
    'Entre 21 e 60 documentos',
    'Entre 61 e 100 documentos',
    'Entre 101 e 500 documentos',
    'Acima de 500 documentos',
  ],
};
