import { axios } from '@/plugins';
import * as membershipService from '@/services/memberships';
import { membershipUtils } from '@/utils';

const PER_PAGE = 10;

// TOGGLE_GROUP_PERMISSIONS_ENABLED
// Remove rootGetters
const fetchMemberships = async (
  { getters, commit, dispatch, rootGetters },
  payload = { page: 1, perPage: PER_PAGE }
) => {
  const { page, perPage } = payload;

  // TOGGLE_GROUP_PERMISSIONS_ENABLED
  // Remove isToggleEnabled and isTogglePermissionsEnabled
  const isToggleEnabled = rootGetters['featureFlags/isToggleEnabled'];
  const isToggleGroupPermissionsEnabled = isToggleEnabled('TOGGLE_GROUP_PERMISSIONS_ENABLED');

  try {
    const endpoint = _.get(getters, 'getLinks.self');
    const searchParams = new URLSearchParams();

    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: Remove if and keep if inner code
    if (isToggleGroupPermissionsEnabled) {
      searchParams.append('per_page', perPage || PER_PAGE);
      searchParams.append('page', page);
    }

    const requestUrl = `${endpoint}?${searchParams}`;

    const { data } = await axios.get(requestUrl);

    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: Remove isToggleGroupPermissionsEnabled from if condition
    // If the page is greater than the pageCount, we need to fetch the previous page
    if (isToggleGroupPermissionsEnabled && data.pagination.pageCount < page) {
      const newPayload = {
        ...payload,
        page: page - 1,
      };
      await dispatch('fetchMemberships', newPayload);

      return;
    }

    commit('SET_MEMBERSHIPS', data.memberships);
    commit('SET_LINKS', data.links);
    commit('SET_PAGINATION', data.pagination);
  } catch (err) {
    throw err;
  }
};

const updateGroupsUsers = async ({ dispatch, rootGetters }, payload) => {
  const groups = rootGetters['group/getGroups'];
  const { groupInviteIds, membershipId } = payload;

  if (!membershipId || groupInviteIds?.length > 0) {
    groupInviteIds?.forEach(async (groupInviteId) => {
      const groupFound = groups.find((group) => group.id === groupInviteId);
      await dispatch('group/fetchGroupInvites', groupFound, { root: true });
      await dispatch('group/fetchGroupMembers', groupFound, { root: true });
    });
  }

  /* When membershipId is present and groupInviteIds is empty, we need to
    fetch all groups users */
  if (membershipId && groupInviteIds?.length === 0) {
    groups.forEach(async (group) => {
      await dispatch('group/fetchGroupMembers', group, { root: true });
      await dispatch('group/fetchGroupInvites', group, { root: true });
    });
  }
};

const inviteMembership = async ({ dispatch, rootGetters }, payload) => {
  // TOGGLE_GROUP_PERMISSIONS_ENABLED
  // TODO: Remove rootGetters, isToggleEnabled and isTogglePermissionsEnabled
  const isToggleEnabled = rootGetters['featureFlags/isToggleEnabled'];
  const isToggleGroupPermissionsEnabled = isToggleEnabled('TOGGLE_GROUP_PERMISSIONS_ENABLED');
  try {
    await membershipService.inviteMembership(payload);

    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: Remove if and keep if inner code
    if (isToggleGroupPermissionsEnabled) {
      await dispatch('updateGroupsUsers', {
        groupInviteIds: payload.bodyData?.invite?.group_ids,
      });
    }

    await dispatch('fetchMemberships');
  } catch (err) {
    throw err;
  }
};

const updateMembership = async ({ dispatch, getters, rootGetters }, payload) => {
  const membershipsPagination = getters.getMembershipsPagination;
  const invitesPagination = rootGetters['invite/getInvitesPagination'];
  // TOGGLE_GROUP_PERMISSIONS_ENABLED
  // TODO: Remove isToggleEnabled and isTogglePermissionsEnabled
  const isToggleEnabled = rootGetters['featureFlags/isToggleEnabled'];
  const isToggleGroupPermissionsEnabled = isToggleEnabled('TOGGLE_GROUP_PERMISSIONS_ENABLED');

  try {
    const requestData = membershipUtils.buildMembershipUpdateRequest(payload);
    await membershipService.updateMembership(requestData);

    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: Remove if and else keep if inner code
    if (isToggleGroupPermissionsEnabled) {
      await dispatch('updateGroupsUsers', {
        groupInviteIds: payload.membership?.group_ids,
        membershipId: payload.membership?.id,
      });
      await dispatch('invite/fetchInvites', invitesPagination, { root: true });
      await dispatch('fetchMemberships', membershipsPagination);
    } else {
      await dispatch('fetchMemberships');
    }
  } catch (err) {
    throw err;
  }
};

const deleteMembership = async ({ dispatch, getters, rootGetters }, payload) => {
  const membershipsPagination = getters.getMembershipsPagination;
  const invitesPagination = rootGetters['invite/getInvitesPagination'];
  // TOGGLE_GROUP_PERMISSIONS_ENABLED
  // TODO: Remove isToggleEnabled and isTogglePermissionsEnabled
  const isToggleEnabled = rootGetters['featureFlags/isToggleEnabled'];
  const isToggleGroupPermissionsEnabled = isToggleEnabled('TOGGLE_GROUP_PERMISSIONS_ENABLED');

  try {
    const requestData = membershipUtils.buildMembershipDeleteRequest(payload);
    await membershipService.deleteMembership(requestData);

    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: Remove if and else and keep if inner code
    if (isToggleGroupPermissionsEnabled) {
      await dispatch('updateGroupsUsers', {
        groupInviteIds: payload.membership?.groupsIds,
        membershipId: payload.membership?.id,
      });
      await dispatch('invite/fetchInvites', invitesPagination, { root: true });
      await dispatch('fetchMemberships', membershipsPagination);
    } else {
      await dispatch('fetchMemberships');
    }
  } catch (err) {
    throw err;
  }
};

const fetchUserGroups = async ({ rootGetters }, payload) => {
  const { query } = payload;
  const searchParams = new URLSearchParams();

  if (query.length > 0) {
    searchParams.append('q', query);
  }

  const endpoint = rootGetters['info/getLinks']?.users.index;

  const requestUrl = `${endpoint}${searchParams}`;

  try {
    const response = await axios.get(requestUrl);
    return response;
  } catch (err) {
    throw err;
  }
};

export default {
  fetchMemberships,
  fetchUserGroups,
  inviteMembership,
  updateMembership,
  deleteMembership,
  updateGroupsUsers,
};
