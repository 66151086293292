const facialBiometrics = {
  title: 'Facial biometrics',
  documentType: {
    title: 'Which document do you want to use?',
    subtitle: 'Choose the one with the most recent photo',
    iAgreeTo: 'I authorize @:steps.facialBiometrics.title',
    action: 'Take a picture',
    officialDocuments: {
      cnh: {
        title: 'CNH',
        text: 'Driver license',
      },
      rg: {
        title: 'RG',
        text: 'Identity',
      },
    },
  },
  carousel: {
    step1: {
      title: '@:steps.facialBiometrics.title',
      text: "We will take a picture of you and your official document to confirm that's really you",
    },
    step2: {
      title: '@:steps.liveness.carousel.step2.title',
      text: {
        first: '@:steps.liveness.carousel.step2.text.first',
        second: '@:steps.liveness.carousel.step2.text.second',
        third: '@:steps.liveness.carousel.step2.text.third',
        fourth: '@:steps.liveness.carousel.step2.text.fourth',
      },
    },
    step3: {
      title: 'Activate the camera',
      text: 'Access from a camera device is required',
    },
  },
  helpModal: {
    title: '@:steps.facialBiometrics.title',
    whatIs: {
      title: 'What is?',
      description:
        "It is a way to confirm the identity of the signer, by comparing the photo taken with the document's photo",
    },
    why: {
      title: 'Why sign with @:steps.facialBiometrics.title?',
      description:
        'This authentication reduces the chance of fraud, making electronic signatures even more secure.',
    },
    learnMore: {
      base: 'Want to know more? Check out our {0} and {1}.',
      privacyPolicy: 'Privacy Policy',
      helpCenter: 'Help Center',
    },
    authMethod: "* The sender's document choose this authentication",
  },
  cameraError: {
    unsupportedBrowser: 'Your browser does not support.',
    cameraMissing: 'Could not find a camera.',
    cameraBusy: 'Your camera is in use by another application.',
    cameraBlocked: 'Permission denied to camera.',
    unknownCameraError: 'Unexpected error while connect to camera.',
  },
  capture: {
    title: {
      rg: {
        front: 'Front of your RG',
        back: 'Back of your RG',
      },
      cnh: {
        front: 'Front of your CNH',
        back: 'Back of your CNH',
      },
      error: 'We found a problem',
      already: 'Photo already validated!',
    },
    error: {
      front: 'Front of unrecognized document\nTry again',
      back: 'Back of unrecognized document\nTry again',
    },
    success: 'Photo taken successfully!',
    loading: 'We send the photo for review\nWait a moment...',
    warning: {
      rg: 'RG document captured on first attempt',
      cnh: 'CNH document captured on first attempt',
    },
  },
  facematch: {
    error: "We haven't identified you yet.\nTry again with another document",
    loading: 'Comparing selfie with the document photo...',
  },
};

export default facialBiometrics;
