import VueI18n from '@/plugins/vue-i18n';
import ptBr from './pt_BR/ptBr';
import enUs from './en_US/enUs';
import { dateTimeFormats as dateTimeFormatsPtBr } from './pt_BR/dateTimeFormats';

const messages = {
  enUs,
  ptBr,
};

const dateTimeFormats = {
  'pt-BR': dateTimeFormatsPtBr,
};

const i18n = new VueI18n({
  locale: 'ptBr',
  fallbackLocale: {
    ptBr: ['pt-BR', 'pt'],
    default: ['en'],
  },
  messages,
  dateTimeFormats,
});

export default i18n;

export const useI18n = () => ({
  t: (key, values, locale) => i18n.t(key, locale, values),
  tc: (key, choice, values, locale) => i18n.tc(key, choice, locale, values),
  te: (key, locale) => i18n.te(key, locale),
  d: (value, key, locale) => i18n.d(value, key, locale),
  n: (value, format, locale) => i18n.n(value, format, locale),
  locale: i18n.locale,
});
