<template>
  <BaseFormField
    :label="label"
    :required="required"
    :uuid="uuid"
    :help-tooltip="helpTooltip"
    :error-message="errorMessage"
    :hint="hint"
  >
    <input
      :id="uuid"
      :ref="uuid"
      :value="internalValue"
      :name="name"
      :class="[$style.input, errorMessage && $style.error, ...inputClasses]"
      :type="type"
      :disabled="disabled"
      :maxlength="maxlength"
      :readonly="readonly"
      :data-testid="dataTestid"
      :data-ga-event="dataGaEvent"
      :autofocus="autofocus"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      @input="$emit('input', $event.target.value)"
      @blur="blur"
      @keyup="$emit('keyup', $event)"
      @focus="$emit('focus', $event)"
      @focusout="$emit('focusout', $event)"
      @mouseover="$emit('mouseover', $event)"
      @mouseleave="$emit('mouseleave', $event)"
      @click="$emit('click', $event)"
    />
  </BaseFormField>
</template>

<script>
import BaseFormField from '@base/components/BaseFormField.vue';
import { validationsMixin } from '../mixins';

export default {
  name: 'BaseTextInput',
  components: { BaseFormField },
  mixins: [validationsMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customError: {
      type: String,
      default: '',
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
    helpTooltip: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: Array,
      default: () => [],
    },
    dataTestid: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    dataGaEvent: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      internalValue: this.value,
      uuid: '',
    };
  },
  computed: {
    errorMessage() {
      return this.customError || this.validationError;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.setInternalValue(value);
      },
      immediate: true,
    },
  },
  mounted() {
    this.uuid = _.uniqueId('field-');
  },
  methods: {
    setInternalValue(value) {
      this.internalValue = value;
      this.triggerValidation();
    },
    focus() {
      this.$refs[this.uuid].focus();
    },
    blur() {
      this.triggerValidation();
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  padding: var(--space-small-xx) var(--space-small-x);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  background-color: var(--color-neutral-0);
  border: solid 1px var(--color-neutral-400);
  border-radius: var(--border-radius-small);
  box-shadow: inset 0 1px 1px rgba(24, 24, 24, 0.075);

  &:not([disabled]):not([readonly]):focus {
    border: var(--border-width-small) solid #1ecaff;
    outline: 0;
    box-shadow: 0px 0px 3px rgba(30, 202, 255, 0.81), inset 0px 1px 2px rgba(0, 0, 0, 0.306159);
  }

  &[disabled] {
    background: var(--color-neutral-400);
  }

  &[readonly] {
    &:focus {
      outline: none;
    }

    &:hover {
      cursor: default;
    }
  }

  &.error {
    border-color: var(--color-feedback-error-400);
    outline-color: var(--color-feedback-error-400);
  }
}
</style>
