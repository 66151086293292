export default {
  getLinks: (state) => state.links,
  getToggles: (state) => state.toggles,
  getDraft: (state) => state.draft,
  getArchives: (state) => [...state.uploadingArchives, ...state.archives],
  getRubrics: (state) => state.rubrics,
  getDrafts: (state) => state.drafts,
  getPagination: (state) => state.pagination,
  getSigners: (state) => state.signers,
  getGroups: (state) => state.groups,
  getUploadingArchives: (state) => state.uploadingArchives,
  getDeletingArchives: (state) => state.deletingArchives,
  getAlreadyDeletedArchives: (state) => state.alreadyDeletedArchives,
  getFromEnvelope: (state) => state.fromEnvelope,
  getSequenceEnabled: (state) => state.draft?.sequenceEnabled || state.sequenceEnabled,
  getSignatures: (state) => state.positionSign?.signatures,
  getDraftLoading: (state) => state.draftLoading,
};
