const dashboardDetails = {
  title: 'Controle de prazos',
  filter: 'Filtrar',
  list: {
    name: 'Nome do documento',
    date: 'Data',
    detail: 'Detalhe',
  },
  empty: {
    title: 'Nenhuma data a ser exibida',
    text: 'Para exibir os prazos dos documentos é preciso cadastrar uma data em "detalhes" do documento',
    button: 'Veja como funciona',
  },
};

export default dashboardDetails;
