import { axios } from '@/plugins';
import { kebabCase, uniqueId } from 'lodash';

// TODO: move this method to utils in field-creator module
const mountFieldPayload = (fields) => {
  return fields.map((payload) => {
    return {
      ...payload,
      name: uniqueId(`${kebabCase(payload.label)}`),
      type: payload.kind,
    };
  });
};

const sendFields = async ({ getters }, link) => {
  await axios.post(link, {
    flow: {
      formBuilderAttributes: {
        formFields1: mountFieldPayload(getters.getFormFields1),
        formFields2: mountFieldPayload(getters.getFormFields2),
      },
    },
  });
};

export default {
  sendFields,
};
