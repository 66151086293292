import { useScopedToggles } from '@base/hooks/useScopedToggles';
import camelCase from 'lodash/camelCase';

const createScopedToggle = (scope) => useScopedToggles().create(scope);

export default {
  getAccount: (state) => state.account,
  getToggles: (state) => state.toggles,
  isToggleEnabled:
    (state) =>
    (toggleName, params = {}) => {
      const toggleValue = state.toggles[camelCase(toggleName)];
      // eslint-disable-next-line
    if (toggleValue === undefined) console.warn('Toggle not found:', toggleName);
      if (typeof toggleValue !== 'string') return !!toggleValue || false;

      return createScopedToggle(toggleValue).isEnabled(params.scope);
    },
  getUser: (state) => state.account.user,
  getUsers: (state) => state.user,
  getUserAccounts: (state) => state.userAccounts,
  getLinks: (state) => state.links,
  getSidebarLinks: (state) => state.sidebarLinks,
  getRoutes: (state) => state.routes,
  getDownloadProcessing: (state) => state.downloadProcessing,
  getSidebarBadges: (state) => state.sidebarBadges,
  getEmployeesAmount: (state) => state.employeesAmount,
  getConfig: (state) => state.config,
  getRouter: (state) => state.router,
  getABTests: (state) => state.abTests,
};
