<template>
  <section :class="$style.empty">
    <XIcon
      :icon="icon"
      size="2x"
    />

    <div :class="$style.content">
      <h3 :class="$style.title">
        {{ title }}
      </h3>
      <slot name="description">
        <p
          v-if="description"
          :class="$style.description"
        >
          {{ description }}
        </p>
      </slot>
    </div>
    <slot name="action" />
  </section>
</template>

<script>
export default {
  name: 'BaseEmptyList',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;

  .content {
    text-align: center;

    .title {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-bold);
      text-align: center;
    }
  }
}
</style>
