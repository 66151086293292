export const signatureColors = [
  {
    backgroundColor: 'var(--color-support-02-100)',
    borderColor: 'var(--color-support-02-200)',
    textColor: 'var(--color-support-02-600)',
  },
  {
    backgroundColor: 'var(--color-feedback-warning-100)',
    borderColor: 'var(--color-feedback-warning-400)',
    textColor: 'var(--color-feedback-warning-600)',
  },
  {
    backgroundColor: 'var(--color-feedback-error-100)',
    borderColor: 'var(--color-feedback-error-400)',
    textColor: 'var(--color-feedback-error-600)',
  },
  {
    backgroundColor: 'var(--color-brand-primary-100)',
    borderColor: 'var(--color-brand-primary-400)',
    textColor: 'var(--color-brand-primary-600)',
  },
  {
    backgroundColor: 'var(--color-support-03-100)',
    borderColor: 'var(--color-support-03-400)',
    textColor: 'var(--color-support-03-600)',
  },
  {
    backgroundColor: 'var(--color-support-04-100)',
    borderColor: 'var(--color-support-04-400)',
    textColor: 'var(--color-support-04-600)',
  },
  {
    backgroundColor: 'var(--color-feedback-success-100)',
    borderColor: 'var(--color-feedback-success-400)',
    textColor: 'var(--color-feedback-success-600)',
  },
];
