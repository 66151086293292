export default {
  links: {},
  consumptionReport: [],
  consumptionReportsCounters: {
    total: 0,
    items: 0,
  },
  consumptionReportsFilters: {},
  consumptionReportUrl: '',
  permissions: {},
  consumptionReportPagination: {},
};
