import messageTemplate from './message-template';

const accountModule = {
  messageTemplate,
  chooseAccount: {
    title: 'Olá, {name}!',
    subtitle: 'O que deseja fazer hoje?',
    accountType: 'Conta',
    signerAreaType: 'Assinar documentos',
    signerArea: 'Área de assinatura',
    signersCard: {
      title: 'Assinar documentos',
      info: 'Sem assinaturas pendentes para você, por enquanto',
      pendingSignatures: 'assinatura pendente | assinaturas pendentes',
      button: 'Acessar área de assinatura',
    },
    operatorCard: {
      title: 'Enviar documentos',
      info: 'Teste por 14 dias grátis tudo que a Clicksign oferece',
      link: 'Como criar nova conta?',
      hasAccount: {
        info: 'Acesse a conta para enviar e acompanhar seus documentos e aceites',
      },
      contentList: [
        'Assinaturas digital e eletrônica',
        'Envios via WhatsApp, SMS ou e-mail',
        'Diversos tipos de Autenticação',
        'Gestão de documentos e aceites',
      ],
      button: 'Criar conta',
    },
  },
  onboarding: {
    firstStep: {
      title: 'O que deseja fazer?',
      signerDescription: 'Assinar um documento que recebi',
      operatorDescription: 'Testar o envio de documentos',
    },
    accountStep: {
      step: 'Passo 1 de 2',
      title: 'Informações da sua conta',
      personal: 'Pessoa Física',
      business: 'Pessoa Jurídica',
      company: {
        label: 'Nome da empresa',
        placeholder: 'Digite o nome da empresa',
      },
      cpf: {
        label: 'CPF',
      },
      birthday: {
        label: 'Data de nascimento',
      },
      phoneNumber: {
        label: 'Telefone',
      },
      segment: 'Em qual segmento você atua?',
      documentsAmount: 'Quantos documentos deseja enviar por mês?',
      reason: 'Por que decidiu criar a conta?',
      selectPlaceholder: 'Selecione...',
      button: 'Avançar',
      loadError: 'Ocorreu um erro ao carregar a página, tente novamente',
      saveError: 'Erro ao salvar as informações. Tente novamente.',
    },
    additionalDataStep: {
      title: 'Ajude a melhorar sua experiência, {userFirstName}',
      segment: 'Em qual segmento você atua?',
      documentsAmount: 'Quantos documentos deseja enviar por mês?',
      reason: 'Por que decidiu criar a conta?',
      selectPlaceholder: 'Selecione...',
      button: 'Avançar',
      loadError: 'Ocorreu um erro ao carregar a página, tente novamente',
      saveError: 'Erro ao salvar as informações. Tente novamente.',
      step: 'Passo 2 de 2',
    },
    creatingAccount: {
      text: 'Criando conta em 3, 2, 1...',
    },
    signOut: 'Sair da conta',
  },
  settings: {
    businessTitle: 'Configurações da empresa: {0}',
    personTitle: 'Configurações: {0}',
  },
  profile: {
    title: 'Informações',
    action: 'Salvar alterações',
    success: 'Informações atualizadas com sucesso',
    error: 'Não foi possível salvar as alterações da conta.',
    fetchError: 'Tivemos problemas em buscar os dados, tente novamente.',
    blockMessage: 'Apenas o administrador da conta pode editar as informações de cadastro.',
    avatar: {
      action: 'Editar perfil',
    },
    account: {
      nameInfo: 'Nome que é exibido na plataforma e em e-mails enviados',
      segment: 'Segmento de atuação',
      contact: 'Forma de contato preferencial',
      phone: 'Telefone',
      whatsapp: 'WhatsApp',
      placeholderPhone: '(__) ____-____',
      email: 'E-mail',
      business: {
        type: 'Pessoa Jurídica',
        name: 'Nome da empresa',
        cnpj: 'CNPJ',
        placeholderCnpj: '__.___.___/____-__',
        tradingName: 'Razão social',
        employeesAmount: 'Número de funcionários',
      },
      personal: {
        birthday: 'Data de nascimento',
        type: 'Pessoa Física',
        name: 'Nome completo',
        cpf: 'CPF',
        placeholderCpf: '___.___.___-__',
        placeholderBirthday: 'dd/mm/aaaa',
      },
    },
    sender: {
      title: 'Remetente customizado',
      text: 'Permite personalizar o remetente dos e-mails enviados pela Clicksign.',
      curSender: 'Remetente atual',
      helpEmail: 'assinatura@clicksign.com',
      changeSender: 'Solicitar alteração',
      more: 'Saiba mais',
    },
    warn: {
      title: 'Alterar tipo de conta',
      text: 'Deseja alterar o tipo da sua conta para {type}?',
      action: 'Alterar',
      close: 'Fechar',
    },
    billing: {
      form: {
        title: 'Atualizar dados de cobrança',
        success: 'Dados atualizados com sucesso',
      },
    },
  },
  branding: {
    title: 'Marca e cores',
    subtitle: 'Serão exibidos no cabeçalho da sua conta, nos e-mails e nas páginas de assinatura.',
    helpLink: 'Dicas de personalização',
    sendLogoText: 'Comece selecionando sua marca',
    headerText: 'Cabeçalho',
    buttonText: 'Botão',
    backgroundColor: 'Cor de fundo',
    textColor: 'Cor do texto',
    reset: 'Redefinir',
    finish: 'Salvar alterações',
    loadError: 'Ocorreu um erro ao carregar a página, tente novamente',
    saveError: 'Ocorreu um erro ao salvar os dados, tente novamente.',
    saveSuccess: 'Suas configurações de personalização foram salvas.',
    contrastAlert: {
      title: 'Botão ilegível. Use cores que contrastem.',
      text: 'Ajuste as cores do botão para garantir uma melhor leitura.',
      link: 'Ver exemplos',
    },
    brandingPreview: {
      title: 'Pré-visualização',
      emailSender: 'E-mail enviado por: {0}',
      altImage: 'Logo da Empresa',
      altClicksignLogo: 'Logo da Clicksign',
      signRequest: 'Solicitação de assinatura de {accountName}',
      button: 'Botão de exemplo',
    },
    brandingUploader: {
      brandingImageAlt: 'Logo carregado',
      logoUploadButton: 'Selecionar sua marca',
      logoHintSize: 'Tamanho ideal: 240x120 pixels',
      logoHintFileType: 'Formatos aceitos: jpeg ou png',
      tooltip: 'Remover',
      sizeError: 'Erro ao carregar a imagem. Escolha um arquivo com menos de 2 MB.',
      typeError: 'Erro ao carregar a imagem. Escolha um arquivo JPEG ou PNG.',
    },
  },
  customSenderModal: {
    request: {
      header: 'Remetente customizado',
      description:
        'Com esta funcionalidade, os e-mails enviados pela Clicksign terão como remetente um e-mail da sua empresa, por exemplo, contato@suaempresa.com.',
      infoText: 'Antes de solicitar, é importante saber:',
      bullets: {
        item1: 'É necessário que você já tenha um domínio corporativo.  Ex.: email@suaempresa.com.',
        item2:
          'Não é possível usar e-mails com domínio genérico.  Ex.: @gmail.com; ou @outlook.com.',
        item3: 'Essa customização não contempla o e-mail de resposta.',
      },
      checkBox: 'Confirmo que li e entendi as informações acima',
      moreInfo: 'Saiba mais',
      button: 'Solicitar agora',
    },
    error: 'Ocorreu uma falha ao solicitar o remetente customizado. Tente novamente.',
    success: {
      header: 'Solicitação recebida',
      description:
        'Entraremos em contato através do e-mail {0} com os próximos passos para ativar o remetente customizado.',
      button: 'Entendi',
    },
  },
  limitExceddedInTrialModal: {
    header: {
      reachLimit: 'Você atingiu o limite de documentos no período de testes',
      formToRequest: 'Fale com um especialista',
      success: 'Solicitação realizada',
    },
    title: {
      createDocumentReachLimit: 'Limite atingido',
      whatappAcceptanceReachLimit: 'Limite de Aceites atingido',
      formToRequest: 'Fale com um especialista',
    },
    subtitle: {
      formToRequest: 'Envie seus dados para entrarmos em contato',
    },
    message: {
      reachLimit: {
        firstParagraph:
          'Você já possui {limit} envios em andamento. Conclua os processos não finalizados acessando a área Rascunhos ou fale com nossa equipe comercial para solicitar envios extras até o fim do período de teste.',
        secondParagraph: 'Já escolheu o plano ideal para você? Contrate e siga usando a Clicksign.',
      },
    },
    formLabels: {
      name: 'Nome',
      email: 'E-mail',
      accountName: 'Empresa',
      phoneNumber: 'Telefone',
    },
    actions: {
      openPlansLink: 'Contratar um plano',
      requestMoreDocuments: 'Solicitar envios extras',
      talkToSpecialist: 'Fale com um especialista',
    },
  },
  brand: {
    title: 'Personalizar',
  },
  memberships: {
    title: 'Usuários',
  },
  groups: {
    title: 'Grupos',
  },
  info: {
    title: 'Informações',
  },
  api: {
    title: 'API',
    error: 'Ocorreu um erro ao carregar a página, tente novamente',
    documentsBalanceAlert: {
      message:
        'Durante o período de teste, é possível criar até 50 documentos via API. Se precisar de um volume maior, ',
      cta: 'teste no ambiente Sandbox',
    },
    buttons: {
      goToSandbox: 'Ir para o sandbox',
      goToApiDocumentation: 'Ver a documentação',
      saveEmail: 'Salvar e-mail',
      createAccessToken: 'Gerar Access Token',
      createWebhook: 'Adicionar Webhook',
      generate: 'Gerar',
      remove: 'Remover',
      activate: 'Ativar',
      widgetEmbeddedPurchase: 'Contratar',
    },
    headers: {
      cardInfo: {
        apiDocumentation: 'Confira a documentação e conheça nossa API',
        sandbox: 'Precisa testar a integração? Acesse o ambiente Sandbox',
      },
      email: {
        title: 'E-mail do usuário da API',
        subtitle: 'O e-mail constará nos eventos dos documentos criados via API.',
        selectPlaceholder: 'Selecione um usuário',
      },
      accessToken: {
        title: 'Access token',
        subtitle: 'Necessário para realizar a autenticação na API da Clicksign.',
        link: 'Saiba mais sobre o Access Token',
      },
      widget: {
        title: 'Clicksign no seu site',
        subtitle:
          'Widget embedded para incorporar o processo de assinatura em seu site ou aplicativo. Custo adicional de R$ 200 por mês.',
        link: 'Saiba mais sobre Clicksign no seu site',
        status: {
          enabled: 'Habilitado',
          waiting: 'Aguarde',
        },
      },
      webhooks: {
        title: 'Webhooks',
        subtitle: 'Comunicam eventos ocorridos nos documentos para uma aplicação externa.',
        link: 'Saiba mais sobre webhooks',
      },
      presentialSignature: {
        title: 'Assinatura Presencial',
        subtitle:
          'Recurso de assinatura presencial. Ideal para uso em terminais de autoatendimento, tablets e similares.',
        link: 'Saiba mais sobre assinatura presencial',
      },
    },
    notification: {
      success: {
        saveEmail: 'E-mail salvo com sucesso',
        createAccessToken: 'Access Token gerado com sucesso',
        removeAccessToken: 'Access Token removido com sucesso',
        createHook: 'Webhook adicionado com sucesso. Consulte o HMAC SHA256 Secret abaixo',
        editHook: 'Webhook editado com sucesso',
        removeHook: 'Webhook removido com sucesso',
        activateHook: 'Webhook ativado com sucesso',
      },
      error: {
        saveEmail: 'Erro ao salvar novo e-mail',
        createAccessToken: 'Erro ao criar access token',
        removeAccessToken: 'Erro ao remover access token',
        createHook: 'Erro ao adicionar webhook',
        removeHook: 'Erro ao remover webhook',
        editHook: 'Erro ao editar webhook',
        activateHook: 'Erro ao ativar webhook',
      },
    },
    tokenInfo: {
      title: 'Token {count}',
      description: 'Descrição',
      accessToken: 'Access token',
      lastOperation: 'Última utilização',
      noLastOperation: 'Nunca foi utilizado',
    },
    hookInfo: {
      url: 'Webhook URL',
      secret: 'HMAC SHA256 Secret',
      events: 'Eventos',
      status: 'Status',
      active: 'Habilitado',
      disabled: 'Desabilitado',
    },
    modal: {
      createAccessToken: {
        title: 'Gerar Access token',
        label: 'Descrição do Access Token',
        placeholder: 'Exemplo: seusistema_token',
        description: 'Utilize para indicar onde ele será usado.',
      },
      removeAccessToken: {
        title: 'Deseja remover este Access token?',
        text: 'A ação não poderá ser desfeita.',
      },
      webhook: {
        selectAllEvents: 'Selecionar todos os eventos',
        title: 'Webhooks',
        label: 'Endereço',
        placeholder: 'https://example.com/postreceive',
      },
      removeWebhook: {
        title: 'Deseja remover este Webhook?',
        text: 'A ação não poderá ser desfeita.',
      },
      widgetEmbedded: {
        header: 'Contrate Widget embedded',
        title: 'Turbine seu plano com Widget embedded',
        subtitle: 'Informe seus dados e entraremos em contato',
        name: 'Nome',
        namePlaceholder: 'Nome do cliente',
        email: 'E-mail',
        emailPlaceholder: 'emailcliente@exemplo.com.br',
        company: 'Empresa',
        companyPlaceholder: 'Empresa do cliente',
        phone: 'Número de telefone',
        phonePlaceholder: '(  ) _____-____',
        waitTitle: 'Informações enviadas',
        waitText: 'Nossa equipe entrará em contato nas próximas 24 horas.',
      },
      webhookTable: {
        active: 'Ativar',
        event: 'Evento',
        description: 'Descrição',
        events: {
          upload: 'Ocorre quando é realizado o upload de um documento.',
          addSigner: 'Ocorre quando são adicionados signatários a um documento.',
          removeSigner: 'Ocorre quando são removidos signatários de um documento.',
          sign: 'Ocorre quando um signatário assina um documento',
          close: 'Ocorre quando um documento é finalizado manualmente',
          autoClose:
            'Ocorre quando um documento é finalizado automaticamente logo após a última assinatura.',
          deadline:
            'Ocorre quando a data limite de assinatura de um documento for atingida. Se o documento contiver ao menos uma assinatura, ele é finalizado. Caso contrário, o documento é cancelado.',
          documentClosed: 'Ocorre quando o documento assinado está pronto para download.',
          cancel: 'Ocorre quando um documento é cancelado manualmente',
          updateDeadline: 'Ocorre quando a data limite de assinatura de um documento é alterada.',
          updateAutoClose:
            'Ocorre quando a opção de finalização automática de um documento é alterada.',
          addImage: 'Ocorre quando é realizado um evento customizado de imagem para um documento.',
          updateLocale: 'Ocorre quando é alterado o idioma de um documento.',
          refusal: 'Ocorre quando um documento é recusado.',
          custom:
            'Ocorre quando é realizado um evento customizado de token (e-mail, sms) para um documento',
          acceptanceTermEnqueued:
            'Ocorre quando um “Aceite via WhatsApp” está na fila para envio (destinatário já possui um Aceite em processo).',
          acceptanceTermSent: 'Ocorre quando um “Aceite via WhatsApp” é enviado.',
          acceptanceTermCompleted:
            'Ocorre quando um “Aceite via WhatsApp” é finalizado com sucesso.',
          acceptanceTermRefused:
            'Ocorre quando um “Aceite via WhatsApp” é recusado (encerrado pelo destinatário).',
          acceptanceTermCanceled:
            'Ocorre quando um “Aceite via WhatsApp” é cancelado pelo remetente.',
          acceptanceTermExpired:
            'Ocorre quando um “Aceite via Whatsapp” é expirado (tempo limite para aceite atingido).',
          acceptanceTermError:
            'Ocorre quando há um erro interno na funcionalidade junto à Clicksign ou ao WhatsApp',
          attemptsByWhatsappExceeded:
            'Ocorre quando as tentativas de reenvio de Token via WhatsApp são excedidas',
          attemptsByLivenessOrFacematchExceeded:
            'Ocorre quando as tentativas de autenticação via Selfie dinâmica ou Biometria facial são excedidas',
          livenessRefused: 'Ocorre quando a análise da Selfie dinâmica é recusada.',
          facematchRefused: 'Ocorre quando a análise da Biometria Facial é recusada.',
          documentscopyRefused: 'Ocorre quando a análise da Documentscopia é recusada.',
          biometricRefused: 'Ocorre quando a análise da Biometria Facial SERPRO é recusada.',
        },
      },
    },
  },
  consumption: {
    title: 'Plano e Cobrança',
  },
  betaProgram: {
    title: 'Programa Beta',
  },
  saml: {
    title: 'Segurança',
    helpLink: 'Saiba mais sobre SSO',
    status: {
      title: 'Status SSO',
      enabled: 'Habilitado',
      disabled: 'Desabilitado',
      syncing: 'Importando metadados',
    },
    sso: {
      subtitle: 'Single Sign On (SSO)',
      samlIdentity: 'Domínio da empresa',
      samlFile: 'Envie um arquivo XML para importar metadados',
      samlFileChoice: 'Enviar arquivo',
      identityProvider: 'A identificação é gerada automaticamente após a importação dos metadados',
      upload: 'Upload feito com sucesso.',
      fileTypeError: 'Envie um arquivo em formato XML',
      warning: 'Exemplo: seudominio.com.br',
      verifySamlIdentity: 'Verificar domínio',
      deleteSamlIdentity: 'Excluir domínio',
      verificationInProgress: 'Verificação em andamento',
      verificationError: 'Erro no início da verificação. Tente novamente.',
      verificationSuccess: 'Verificação realizada com sucesso',
      verifiedDomain: 'Domínio verificado',
    },
    validateIdentityProviderModal: {
      verify: {
        title: 'Verificar domínio por meio de registro DNS',
        firstStep:
          'Acesse sua conta no provedor de nome de domínio (goddady, Google Domains ou semelhante)',
        secondStep: 'Adicione o registro TXT abaixo na configuração do DNS de {domain}',
        thirdStep: 'Após concluir a segunda etapa, clique em “Verificar” abaixo.',
        disclaimer:
          'Certifique-se de que o registro TXT foi adicionado corretamente antes de avançar. Diferenças no registro podem atrapalhar a verificação.',
        confirmButton: 'Verificar',
        cancelButton: 'Fechar',
      },
      fail: {
        title: 'Falha na verificação da propriedade',
        firstParagraph:
          'Certifique-se de que o registro TXT foi adicionado corretamente  às configurações do DNS. Se estiver tudo certo, tente verificar o domínio novamente após 24 horas.',
        confirmButton: 'Voltar',
        cancelButton: 'Fechar',
      },
    },
    domainExclusionConfirmationModal: {
      title: 'Excluir domínio da empresa?',
      content:
        'Ao confirmar, o Single Sign On será desabilitado em sua conta. Para habilitá-lo novamente será necessário refazer o processo de configuração.',
      checkbox: 'Confirmo que desejo excluir o domínio',
      actionConfirm: 'Excluir',
      actionCancel: 'Cancelar',
    },
    domainExcludeToast: {
      success: 'Domínio excluído com sucesso',
      error: 'Erro na exclusão do domínio. Tente novamente.',
    },
    provider: {
      subtitle: 'Identity Provider',
      urn: 'Relying Party Identifier (URN)',
      assertionEndpoint: 'SAML Assertion Consumer Endpoint',
      assertionEndpointInfo: 'Esta é uma requisição POST',
      copy: 'Copiar',
      copied: 'Dados copiados com sucesso!',
    },
    fieldMap: {
      attribute: 'Atributo SAML | Atributo SAML {index}',
      userGroup: 'Atributo do grupo de usuários',
      addAttribute: 'Adicionar novo atributo',
    },
  },
  sessionSettings: {
    title: 'Tempo limite de sessão',
    saveAction: 'Salvar Alteração',
    knowMore: 'Saiba mais sobre tempo limite de sessão',
    timeSelect: {
      onlyMinutes: '{minutes} minutos',
      onlyHour: '{hours} hora | {hours} horas',
      hourAndMinutes: '{hours} hora e {minutes} minutos | {hours} horas e {minutes} minutos',
      default: '{hours} horas (padrão)',
    },
    successMessage: 'Tempo limite de sessão definido com sucesso',
    errorMessage: 'Erro ao definir tempo limite de sessão. Tente novamente.',
  },
  enabledIpRange: {
    title: 'Limitação de acesso por IP',
    helpLink: 'Saiba mais sobre limitação de acesso',
    addNewRange: 'Adicionar novo alcance',
    emailNotify: 'Notificar via e-mail',
    fields: {
      name: 'Identificação dos IPs autorizados',
      address: 'IP CIDR',
    },
    placeholder: {
      name: 'Exemplo: Filial João Pessoa',
      email: 'email@exemplo.com.br',
    },
    tooltip: {
      remove: 'Remover',
      emailTitle: 'Como funciona?',
      emailText: 'A notificação será enviada caso um IP fora do limite determinado tente acessar',
    },
    modal: {
      remove: {
        title: 'Deseja remover este alcance?',
        text: 'A ação não poderá ser desfeita.',
      },
    },
    notification: {
      email: {
        enabled: 'Notificação via e-mail ativada',
        disabled: 'Notificação via e-mail desativada',
      },
      error: 'Erro ao salvar a limitação de acesso. Tente novamente.',
    },
  },
  modalTrialExpired: {
    title: 'Você atingiu o limite de tempo para teste',
    content: {
      subtitle: 'Limite de tempo atingido',
      timeSpent: 'Você já aproveitou os 14 dias de teste gratuito, mas não precisa parar por aí!',
      choosePlan: 'Escolha o plano ideal para você e continue usando a Clicksign.',
    },
    imageAlt: 'person-trial-expired-image',
    purchase: 'Contrate um plano',
  },
  signerArea: {
    title: 'Área do signatário',
    content: {
      text: 'Em construção!',
      info: 'Em breve, você poderá visualizar e assinar aqui seus documentos pendentes',
    },
  },
};

export default accountModule;
