export default {
  SET_FIELDS(state, payload) {
    state.formFields1 = payload.formFields1;
    state.formFields2 = payload.formFields2;
  },
  UPDATE_FIELD(state, { value, index, form }) {
    state[`formFields${form}`][index] = value;
  },
  DELETE_FIELD(state, { index, form }) {
    state[`formFields${form}`].splice(index, 1);
  },
  ADD_FIELD(state, { form, value }) {
    state[`formFields${form}`].push({ ...value });
  },
  REORDER_FIELDS(state, { form, fields }) {
    state[`formFields${form}`] = fields;
  },
};
