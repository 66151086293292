import BaseToasterLinkError from '@base/components/BaseToasterLinkError.vue';

export default [
  {
    name: 'info',
    title: 'signer.infoStep.envelope.title',
    component: 'EnvelopeSignerInfoForm',
    form: 'signerInfo',
    props: {
      signerAuths: 'signerAuths',
      signerCommunicate: 'signerCommunicate',
      signerSignAs: 'signerSignAs',
      email: 'signerInfo.email',
      phoneNumber: 'signerInfo.phoneNumber',
      name: 'signerInfo.name',
      birthday: 'signerInfo.birthday',
      hasDocumentation: 'signerInfo.hasDocumentation',
      documentation: 'signerInfo.documentation',
      signAs: 'signerInfo.signAs',
      auths: 'signerInfo.auths',
      communicateBy: 'signerInfo.communicateBy',
      contact: 'signerInfo.contact',
      officialDocumentEnabled: 'signerInfo.officialDocumentEnabled',
      selfieEnabled: 'signerInfo.selfieEnabled',
      handwrittenEnabled: 'signerInfo.handwrittenEnabled',
      livenessEnabled: 'signerInfo.livenessEnabled',
      additionalAuths: 'additionalAuthsItems',
      facialBiometricsEnabled: 'signerInfo.facialBiometricsEnabled',
      transferSaveAsContactSwitch: 'transferSaveAsContactSwitch',
    },
    baseState: {
      email: '',
      phoneNumber: null,
      name: '',
      birthday: '',
      hasDocumentation: true,
      documentation: '',
      signAs: '',
      auths: [],
      communicateBy: '',
      officialDocumentEnabled: false,
      selfieEnabled: false,
      handwrittenEnabled: false,
      contact: true,
      livenessEnabled: false,
      facialBiometricsEnabled: false,
    },
    action: async (vm, payload) => {
      if (payload.contact) {
        const url = vm.$store.getters['signer/getLinks']?.contacts;

        await vm.$store.dispatch('signer/createContact', { url, payload, context: vm.context });
      }
    },
    afterAction: (vm) => {
      const signerInfo = {
        ...vm.signerInfo,
        context: vm.context,
      };
      Object.assign(vm.signAs, { signers: [signerInfo] });
    },
  },
  {
    name: 'signAs',
    title: 'signer.infoStep.signAs.label',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signerSignAs: 'getSignerSignAsItems',
      signAsList: 'signAs.signAsList',
      flexibleContexts: 'flexibleContexts',
    },
    baseState: {
      signers: [],
      signAsList: {},
      lists: {},
    },
    action: async (vm, payload) => {
      // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
      // TODO: Remove toggle condicion
      const newSetupToggle = vm?.$store.getters['draft/getToggles'].newSetup;
      if (newSetupToggle) {
        const { signers } = payload;

        const promise = new Promise((resolve, reject) => {
          vm.$emit('should-create-or-have-envelope', { resolve, reject });
        });

        const promiseReturn = await promise;
        if (promiseReturn.error) return false;

        const response = await Promise.all(
          signers.map(async (signer) => {
            await vm?.$store.dispatch('draft/addSignerOperation', signer);
          })
        );
        vm.$emit('save');
        return response;
      }
      const url = vm?.$store.getters['draft/getLinks'].signers;
      const response = await vm.$store.dispatch('signer/linkFlexibleSigners', {
        url,
        payload,
      });
      vm.$emit('save', response);
      return response;
    },
    afterAction: async (vm, payload) => {
      const signers = payload?.signers;
      const lists = payload?.lists;
      vm.$set(vm.messageToSend, 'signers', signers);
      vm.$set(vm.messageToSend, 'lists', lists);

      if (!vm.steps.includes('message')) return;

      const activeGroup = payload?.current_group;
      const isSequenceEnabled = signers.some((signer) => signer.group !== activeGroup);

      vm.$set(vm.messageToSend, 'isSequenceEnabled', isSequenceEnabled);
    },
    errorHandler: (vm, err) => {
      if (!(err && err.response && err.response.status === 422)) return;
      const { data } = err.response;
      if (_.isArray(data)) {
        const errors = data?.filter((error) => !_.isEmpty(error.signers.errors));
        const signersErrors = _.reduce(
          errors,
          (map, value) => {
            const { signerId, signAs } = value.signers;
            const signAsList = { [signerId]: map[signerId] || [] };
            const signerSignAsErrors = _.get(signAsList, signerId);
            return Object.assign(map, { [signerId]: [...signerSignAsErrors, signAs] });
          },
          {}
        );
        vm.$set(vm, 'errorMessages', signersErrors);
        vm.$nextTick(() => {
          vm.hasCurrentStepValidationError();
        });
      } else {
        vm.$toast.error({
          component: BaseToasterLinkError,
          props: {
            message: vm.$t('signer.error.generic.action'),
            linkText: vm.$t('signer.error.generic.supportLinkText'),
            link: 'https://www.clicksign.com/suporte/',
          },
        });
      }
    },
  },
  {
    name: 'message',
    component: 'SignerMessageStep',
    form: 'messageToSend',
    props: {
      signerSignAs: 'signerSignAs',
      signers: 'messageToSend.signers',
      message: 'messageToSend.message',
      messageTemplates: 'messageTemplates',
    },
    baseState: { message: '' },
    action: async (vm, form) => {
      const signers = form?.signers;
      const lists = form?.lists;
      const message = form?.message;
      const isSequenceEnabled = vm?.$store?.state?.document?.sequence_enabled;

      if (isSequenceEnabled) {
        await Promise.all(
          lists.map(async (list) => {
            const url = list?.links.self;
            await vm.$store.dispatch('signer/updateList', { url, message });
          })
        );
      } else {
        await Promise.all(
          signers.map(async (signer) => {
            const url = signer?.links.notifySignature;
            await vm.$store.dispatch('signer/notifySignature', { url, message });
          })
        );
      }
    },
    abort: (vm) => {
      vm.openModal('cancelSigner');
    },
  },
];
