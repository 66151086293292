const documentBase = {
  settings: {
    title: 'Configurações',
    deadline: {
      title: 'Data limite para assinaturas',
      tooltip:
        'Quando a data limite do documento for atingida, o documento será finalizado automaticamente. Caso não conste nenhuma assinatura ou existam assinaturas recusadas, ele será cancelado. | Quando a data limite dos documentos for atingida, os documentos serão finalizados automaticamente. Caso não conste nenhuma assinatura ou existam assinaturas recusadas, eles serão cancelados.',
      subtitle: 'Assinaturas não poderão ser efetuadas após atingida a data limite.',
    },
    refusal: {
      title: 'Pausar assinatura após a recusa',
      checkbox:
        'Se alguém recusar, o processo de assinatura será pausado, impedindo com que outras pessoas assinem e que o documento seja finalizado.',
    },
    autoReminder: {
      title: 'Lembretes automáticos via e-mail',
      subtitle: 'Envio de até 3 lembretes por e-mail.',
      whatsappWarning: 'Documentos enviados via WhatsApp não receberão lembretes.',
      envelopeWarning: {
        title: 'Recorrência do envio: a cada 3 dias',
        subtitle: 'Envio máximo de 3 lembretes.',
      },
      label: 'Enviar lembretes automaticamente a cada',
      day: '{day} dia | {day} dias',
      neverDay: 'Não enviar lembretes',
      eachDay: 'A cada {day} dia | A cada {day} dias',
      autoReminderLimit: 'Enviar lembretes automaticamente',
    },
    rubric: {
      title: 'Rubrica eletrônica',
      subtitle: 'Opções para confirmar a visualização de um conteúdo.',
      clauseLabel: 'Confirmação de leitura de cláusulas',
      fullReadConfirmationLabel: 'Visualização completa do documento',
      sealLabel: 'Rubrica no canto da página',
      nonOptionLabel: 'Não habilitar rubrica eletrônica',
      envelope: {
        subtitle: 'Quem assina deverá confirmar ciência do conteúdo por meio da rubrica',
        checkbox: 'Solicitar rubrica no canto da página',
        electronicRubric: 'Solicitar rubrica',
      },
    },
    documentLanguage: {
      title: 'Idioma',
      subtitle: 'A página de assinatura e os e-mails serão exibidos no idioma selecionado',
      options: {
        english: 'Inglês (EUA)',
        portuguese: 'Português',
      },
    },
    autoSaveToFuture: 'Suas configurações serão salvas automaticamente para o próximo uso',
    deadlineSignature: 'Data limite para assinatura',
    daysRemainingUntilDeadline:
      'Falta menos de um dia para a data limite | Falta {day} dia para a data limite | Faltam {day} dias para a data limite',
    pauseOnRefusal: 'Pausar processo de assinatura após a recusa',
    positionRubric: 'Posicionar campos de assinatura e rubrica',
    documentCloseFinish: 'Finalização',
    documentClose: {
      title: 'Finalização',
      subtitle: 'Quando os documentos enviados devem ser finalizados?',
      auto: 'Após todos assinarem, automaticamente',
      manual: 'Manualmente ou até a data limite. Assim é possível revisar antes de finalizar',
    },
  },
  documentsBalanceAlert: {
    message:
      'Você ainda possui {0} envio de documentos para usar no seu período de teste. | Você ainda possui {0} envios de documento para usar no seu período de teste.',
  },
};

export default documentBase;
