const layout = {
  sidebar: {
    addDocuments: 'Adicionar documentos',
    navigation: {
      dashboard: 'Início',
      dashboardDocumentDetails: 'Prazos',
      documents: 'Documentos',
      rootFolder: 'Todos',
      runningDocuments: 'Em processo',
      closedDocuments: 'Finalizados',
      canceledDocuments: 'Cancelados',
      downloads: 'Baixados',
      trashBin: 'Lixeira',
      draftPacks: 'Rascunhos',
      trackingNotifications: 'E-mails enviados',
      flowProcesses: 'Aprovação',
      contacts: 'Agenda de contatos',
      reports: 'Relatórios',
      automation: 'Automação',
      newTemplate: 'Criar automação',
      templates: 'Modelos',
      formBuilders: 'Formulários',
      formFlows: 'Fluxos com formulário',
      spreadsheetFlows: 'Fluxos com planilha',
      settings: 'Configurações',
      management: 'Gestão',
      acceptance: 'Aceites',
      acceptanceTermWhatsappIndex: 'Via WhatsApp',
      acceptanceTermContentTemplates: 'Modelos',
      pendingAnswers: 'Respostas pendentes',
    },
    needHelp: 'Precisa de ajuda?',
    upgrade: 'Upgrade',
  },
  header: {
    search: {
      placeholder: 'Buscar por documento',
      action: 'Buscar',
      tip: 'Nome do documento, nome ou e-mail do signatário',
      history: 'Últimas pesquisas',
      suggestion: 'Sugestão da busca',
      empty: 'Não encontramos resultados para sua busca.',
    },
    organizations: 'Organizações que você faz parte',
    signerArea: 'Minha área de assinatura',
    signer: 'Documentos para assinar',
    editProfile: 'Configurações do perfil',
    helpCenter: 'Ajuda',
    signout: 'Sair',
    closeLink: 'Sair',
    backToHome: 'Voltar para o Início',
    continueTrial: 'Fechar e testar grátis',
    headway: {
      title: 'Atualizações da plataforma',
      readMore: 'Saiba mais',
      footer: 'Histórico de atualizações 👉',
    },
    filter: {
      title: 'Filtrar documentos',
      // ADVANCED_SEARCH_ENABLED
      // TODO: Remove maintenance locale
      maintenance: {
        title: 'Filtros temporariamente indisponíveis',
        description:
          'Estamos trabalhando para melhorar sua experiência. Agradecemos pela compreensão!',
      },
      statusSelect: {
        label: 'Status',
        placeholder: 'Qualquer status',
      },
      status: {
        running: 'Em processo',
        closed: 'Finalizados',
        canceled: 'Cancelados',
      },
      documentationInput: 'CPF do signatário',
      createdAt: 'Data do envio',
      deadlineAt: 'Data limite de assinatura',
      finishedAt: 'Data do documento finalizado',
      filterDateRange: {
        placeholder: 'Tudo',
        tooltip: 'Não aplicável a documentos em processo',
      },
      buttons: {
        clear: 'Limpar filtros',
        apply: 'Aplicar',
      },
      disabled: 'Desabilitado',
    },
    dropdown: {
      signerArea: 'Minha área de assinatura ',
      signerDashboard: 'Documentos para assinar',
      accounts: 'Contas',
      config: 'Configurações do perfil',
      logout: 'Sair',
      createAccount: 'Criar conta',
    },
  },
  sandboxBanner: {
    title: 'Ambiente SANDBOX - Sem valor legal',
  },
  mobileAlert: {
    header: 'Acesse pelo computador',
    description: 'Acesse pelo computador para tirar maior proveito da Clicksign!',
  },
};
export default layout;
