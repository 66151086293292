import documentsBase from '@document/locales/pt_BR/base';
import documentSigner from '@document/locales/pt_BR/documentShow/signer';

const document = {
  base: documentsBase,
  signer: documentSigner,
  signatures: {
    signatures: 'Assinaturas',
    signAs: 'Assinar como',
    auth_as: 'Autenticar usando',
    runningAt: '%{days_left} para assinar. Em processo até %{deadline_at} %{time_limit}',
    closedAt: 'Finalizado em %{finished_at}',
    canceledAt: 'Cancelado em %{canceled_at}',
    addedAt: 'Adicionado em %{added_at}',
    document_status: 'Documento %{status}',
    duplicate: 'Duplicar documento',
    finish: 'Finalizar',
    cancel: 'Cancelar',
    download: 'Baixar',
    downloadOriginal: 'Baixar documento original',
    downloadSigned: 'Baixar documento assinado',
    downloadOriginalSigned: 'Baixar documento original e assinado',
    signers: 'Signatários',
    noSignersAdded: 'Nenhum signatário adicionado.',
    signStatus: 'Status de assinaturas no documento',
    addSigners: 'Adicionar novos signatários',
    signerEmail: 'Email do signatário',
    email: 'E-mail',
    sendToEnvelope: 'Para alterar signatários, documentos, configurações ou enviar lembretes,',
    accessTheEnvelope: 'acesse o envelope.',
    sms: 'SMS',
    cellphone: 'Celular',
    selectLanguage: 'Selecione o idioma do documento',
    signerOptions: 'Configurações do signatário',
    informedCpf: 'CPF informado',
    waitingSignature: 'Aguardando assinatura',
    didNotSign: 'Não assinou',
    sendReminder: 'Enviar lembrete',
    save: 'Salvar',
    fileName: 'Nome do arquivo:',
    showTimestamps: 'Ver carimbo do tempo',
    list: {
      title: 'Lista de signatários',
      signaturesDone: '{totalSignatures}/{totalSigners} Assinaturas efetuadas',
    },
    selfie: {
      title: 'Selfie com documento',
    },
    addressProof: {
      title: 'Comprovante de residência',
    },
    handwritten: {
      title: 'Assinatura manuscrita',
    },
    officialDocument: {
      title: 'Documento oficial',
      titleFront: 'Frente do documento oficial',
      titleBack: 'Verso do documento oficial',
    },
    liveness: {
      title: 'Selfie dinâmica',
    },
    facialBiometrics: {
      title: 'Biometria facial',
    },
    cancelModal: {
      title: 'Cancelar o documento?',
      text: 'Ao cancelar, todas as assinaturas já efetuadas serão invalidadas.',
      checkbox: 'Selecione para confirmar o cancelamento',
      confirmButton: 'Confirmar cancelamento',
    },
    finishModal: {
      title: 'Finalizar o documento?',
      text: 'O documento finalizado fica indisponível para novas assinaturas.',
      view: 'Você poderá apenas visualizá-lo.',
      confirmButton: 'Finalizar',
    },
    timestampModal: {
      title: 'Informações do carimbo do tempo',
      description:
        'O carimbo do tempo atesta a data e hora em que cada assinatura com certificado digital ocorreu. Veja os detalhes das assinaturas:',
      certifierName: 'Emitido por:',
      localDate: 'Data Local:',
      utcDate: 'Data UTC:',
    },
    blockOrderingModal: {
      title: 'Ordenação de assinaturas indisponível',
      description: {
        reason:
          'Não é possível alterar a ordem das assinaturas em um documento que já foi enviado.',
        solution: 'Para usar a ordenação, ative-a ao adicionar signatários na etapa de envio.',
        link: 'Saiba mais sobre a ordenação de assinaturas.',
      },
    },
    signersGroups: {
      name: 'Grupo {id}',
    },
  },
  historic: {
    events: {
      title: 'EVENTOS',
      info: 'Histórico dos eventos ocorridos no documento.',
    },
    reminders: {
      title: 'LEMBRETES ENVIADOS',
      info: 'Lembretes que foram enviados automaticamente ou enviados por um operador.',
      noReminders: 'Nenhum lembrete enviado',
      sent: 'Enviado para:',
    },
  },
  edit: {
    signatures: 'Assinaturas',
    historic: 'Histórico',
    document: 'Documento',
    details: 'Detalhes',
    flow: 'Fluxo da Automação',
    settings: 'Configurações',
    running: 'Em processo',
    closed: 'Finalizados',
    canceled: 'Cancelados',
    notification: {
      addSigner: 'Signatário adicionado com sucesso!',
      removeSigner: 'Signatário removido com sucesso!',
    },
  },
  cancel: {
    success: 'Documento {0} cancelado com sucesso',
  },
  finish: {
    success: 'Documento {0} finalizado com sucesso',
  },
  settings: {
    deadlineDate: 'DATA LIMITE PARA ASSINATURAS',
    deadlineDateInfo: 'Assinaturas não poderão ser efetuadas após atingida a data limite.',
    autoReminders: 'LEMBRETES AUTOMÁTICOS',
    autoRemindersInfo:
      'Envio de até 3 lembretes por e-mail para signatários que ainda não assinaram o documento.',
    sendReminders: 'Enviar lembretes automaticamente a cada',
    documentLanguage: 'IDIOMA DO DOCUMENTO',
    documentLanguageInfo:
      'A página de assinatura e os e-mails serão exibidos no idioma selecionado.',
    closeDocument: 'FINALIZAÇÃO DO DOCUMENTO',
    closeDocumentInfo: 'Escolha de que forma o documento será finalizado',
    autoClose: 'Após a última assinatura o documento será finalizado automaticamente.',
    manualClose:
      'Manualmente. Você terá que finalizar o documento manualmente, caso contrário, ele será finalizado na data limite de assinatura.',
    english: 'Inglês (EUA)',
    portuguese: 'Português',
    notifications: {
      save: 'Configurações salvas com sucesso!',
    },
  },
  message: {
    title: 'Mensagem',
    info: 'Esta mensagem irá para todos os signatários deste documento. Se a mensagem for respondida, será encaminhada diretamente para o seu e-mail.',
    message_placeholder: 'Digite sua mensagem aqui.',
    alert: {
      whatsappDisable: 'Mensagem desabilitada para assinaturas via WhatsApp',
      whatsappWarning: 'A mensagem abaixo será encaminhada apenas para quem assinar por e-mail',
    },
  },
  signaturesKind: {
    sign: 'Assinar',
    witness: 'Assinar como testemunha',
    party: 'Assinar como parte',
    intervening: 'Assinar como interveniente',
    receipt: 'Assinar para acusar recebimento',
    approve: 'Assinar para aprovar',
    endorser: 'Assinar como endossante',
    endorsee: 'Assinar como endossatário',
    administrator: 'Assinar como administrador',
    guarantor: 'Assinar como avalista',
    transferor: 'Assinar como cedente',
    transferee: 'Assinar como cessionário',
    contractee: 'Assinar como contratada',
    contractor: 'Assinar como contratante',
    jointDebtor: 'Assinar como devedor solidário',
    issuer: 'Assinar como emitente',
    manager: 'Assinar como gestor',
    buyer: 'Assinar como parte compradora',
    seller: 'Assinar como parte vendedora',
    attorney: 'Assinar como procurador',
    legalRepresentative: 'Assinar como representante legal',
    coResponsible: 'Assinar como responsável solidário',
    validator: 'Assinar como validador',
    surety: 'Assinar como fiador',
    lessor: 'Assinar como locador',
    lessee: 'Assinar como locatário',
    ratify: 'Assinar para homologar',
    employer: 'Assinar como empregador',
    employee: 'Assinar como empregado',
    consenting: 'Assinar como anuente',
    accountant: 'Assinar como contador',
    debtor: 'Assinar como devedor',
    franchisee: 'Assinar como franqueado',
    creditor: 'Assinar como credor',
    franchisor: 'Assinar como franqueador',
    insured: 'Assinar como segurado',
    grantor: 'Assinar como outorgante',
    president: 'Assinar como presidente',
    partner: 'Assinar como sócio',
    comforter: 'Assinar como comodante',
    accountHolder: 'Assinar como correntista',
    distracted: 'Assinar como distratada',
    distracting: 'Assinar como distratante',
    lender: 'Assinar como mutuante',
    associate: 'Assinar como associado',
    broker: 'Assinar como corretor',
    bailee: 'Assinar como fiel depositário',
    collateralProvider: 'Assinar como caucionante',
    pledged: 'Assinar como consignado',
    consignee: 'Assinar como consignatário',
    lawyer: 'Assinar como advogado',
    grantee: 'Assinar como outorgado',
    borrower: 'Assinar como comodatário',
    guarantorSpouse: 'Assinar como cônjuge do fiador',
    legalGuardian: 'Assinar como responsável legal',
    director: 'Assinar como diretor(a)',
    realEstateBroker: 'Assinar como corretor de imóveis',
    insuranceBroker: 'Assinar como corretor de seguros',
    licensor: 'Assinar como licenciante',
    licensed: 'Assinar como licenciada',
    serviceProvider: 'Assinar como prestador(a) de serviços',
    secured: 'Assinar como afiançado',
    consented: 'Assinar como anuído',
    interveningGuarantor: 'Assinar como interveniente garantidor',
    consentingIntervenor: 'Assinar como interveniente anuente',
    surveyor: 'Assinar como vistoriador',
    buildingManager: 'Assinar como síndico(a)',
    intermediary: 'Assinar como intermediário(a)',
    condominiumMember: 'Assinar como condômino',
    owner: 'Assinar como proprietário(a)',
    resident: 'Assinar como morador(a)',
    treasurer: 'Assinar como tesoureiro(a)',
    secretary: 'Assinar como secretário(a)',
    signedAsSign: 'Assinou',
    signedAsApprove: 'Assinou para aprovar',
    signedAsParty: 'Assinou como parte',
    signedAsWitness: 'Assinou como testemunha',
    signedAsIntervening: 'Assinou como interveniente',
    signedAsReceipt: 'Assinou para acusar recebimento',
    signedAsEndorser: 'Assinou como endossante',
    signedAsEndorsee: 'Assinou como endossatário',
    signedAsAdministrator: 'Assinou como administrador',
    signedAsGuarantor: 'Assinou como avalista',
    signedAsTransferor: 'Assinou como cedente',
    signedAsTransferee: 'Assinou como cessionário',
    signedAsContractee: 'Assinou como contratada',
    signedAsContractor: 'Assinou como contratante',
    signedAsJointDebtor: 'Assinou como devedor solidário',
    signedAsIssuer: 'Assinou como emitente',
    signedAsManager: 'Assinou como gestor',
    signedAsBuyer: 'Assinou como parte compradora',
    signedAsSeller: 'Assinou como parte vendedora',
    signedAsAttorney: 'Assinou como procurador',
    signedAsLegalRepresentative: 'Assinou como representante legal',
    signedAsCoResponsible: 'Assinou como responsável solidário',
    signedAsValidator: 'Assinou como validador',
    signedAsSurety: 'Assinou como fiador',
    signedAsLessor: 'Assinou como locador',
    signedAsLessee: 'Assinou como locatário',
    signedAsRatify: 'Assinou para homologar',
    signedAsEmployer: 'Assinou como empregador',
    signedAsEmployee: 'Assinou como empregado',
    signedAsConsenting: 'Assinou como anuente',
    signedAsAccountant: 'Assinou como contador',
    signedAsDebtor: 'Assinou como devedor',
    signedAsFranchisee: 'Assinou como franqueado',
    signedAsCreditor: 'Assinou como credor',
    signedAsFranchisor: 'Assinou como franqueador',
    signedAsInsured: 'Assinou como segurado',
    signedAsGrantor: 'Assinou como outorgante',
    signedAsPresident: 'Assinou como presidente',
    signedAsPartner: 'Assinou como sócio',
    signedAsComforter: 'Assinou como comodante',
    signedAsAccountHolder: 'Assinou como correntista',
    signedAsDistracted: 'Assinou como distratada',
    signedAsDistracting: 'Assinou como distratante',
    signedAsLender: 'Assinou como mutuante',
    signedAsAssociate: 'Assinou como associado',
    signedAsBroker: 'Assinou como corretor',
    signedAsBailee: 'Assinou como fiel depositário',
    signedAsCollateralProvider: 'Assinou como caucionante',
    signedAsPledged: 'Assinou como consignado',
    signedAsConsignee: 'Assinou como consignatário',
    signedAsLawyer: 'Assinou como advogado',
    signedAsGrantee: 'Assinou como outorgado',
    signedAsLoanee: 'Assinou como comodatário',
    signedAsBorrower: 'Assinou como comodatário',
    signedAsGuarantorSpouse: 'Assinou como cônjuge do fiador',
    signedAsLegalGuardian: 'Assinou como responsável legal',
    signedAsDirector: 'Assinou como diretor(a)',
    signedAsRealEstateBroker: 'Assinou como corretor de imóveis',
    signedAsInsuranceBroker: 'Assinou como corretor de seguros',
    signedAsLicensor: 'Assinou como licenciante',
    signedAsLicensed: 'Assinou como licenciada',
    signedAsServiceProvider: 'Assinou como prestador(a) de serviços',
    signedAsSecured: 'Assinou como afiançado',
    signedAsConsented: 'Assinou como anuído',
    signedAsInterveningGuarantor: 'Assinou como interveniente garantidor',
    signedAsConsentingIntervenor: 'Assinou como interveniente anuente',
    signedAsSurveyor: 'Assinou como vistoriador',
    signedAsBuildingManager: 'Assinou como síndico(a)',
    signedAsIntermediary: 'Assinou como intermediário(a)',
    signedAsCondominiumMember: 'Assinou como condômino',
    signedAsOwner: 'Assinou como proprietário(a)',
    signedAsResident: 'Assinou como morador(a)',
    signedAsTreasurer: 'Assinou como tesoureiro(a)',
    signedAsSecretary: 'Assinou como secretário(a)',
  },
  setup: {
    loadingDocuments: 'Carregando documento...',
    seeDocument: 'Ver documento',
    title: 'Lista de signatários',
    info: 'Adicione contatos para assinarem os documentos',
    add: 'Adicionar',
    agenda: 'Agenda',
    ordenation: 'Ordenação',
    noSigners: 'Nenhum signatário adicionado.',
    sent: 'Enviado!',
    documentSentToSigners: 'Documentos disponibilizados para os signatários.',
    seeDocumentList: 'Ver listagem de documentos',
    seeThisDocument: 'Ver este documento',
    steps: {
      document: 'Documento',
      settings: 'Configurações',
      message: 'Mensagem',
    },
  },
  preview: {
    download: 'Baixar',
    page: 'Página',
  },
  list: {
    signature: {
      signAs: 'Assinar como',
      authAs: 'Autenticar usando',
      cellphone: 'Celular',
      cpfValidate: {
        pending: 'Verificação em andamento.',
        conferred: 'Nome confere com a base de dados da Receita Federal.',
        cpfEmpty: 'Verificação não realizada.',
        cpfNotFound: 'CPF informado não existe na base de dados da Receita Federal.',
        cpfInvalid: 'CPF informado não é válido.',
        divergentBirthday:
          'Data de nascimento preenchida diverge da data cadastrada na base de dados da Receita Federal. (%{federal_tax_birthday})',
        divergentName:
          'Nome preenchido diverge do nome cadastrado na base de dados da Receita Federal. (%{federal_tax_name})',
        cpfRegularizationPending:
          'CPF informado com regularização pendente na base de dados da Receita Federal.',
        cpfOwnerDeceased:
          'Proprietário do CPF falecido segundo a base de dados da Receita Federal.',
        cpfSuspended: 'CPF informado está suspenso segundo a base de dados da Receita Federal.',
        cpfCanceled: 'CPF informado está cancelado segundo a base de dados da Receita Federal.',
        cpfNull:
          'CPF anulado após identificação de fraude cadastral segundo a base de dados da Receita Federal.',
        signerValidatorFail: 'Ocorreu um erro durante a consulta de dados na Receita Federal.',
        receitaProxyFail: 'Ocorreu um erro durante a consulta de dados na Receita Federal.',
      },
      nameVerification: 'Verificação do nome',
      signedAt: 'Data da assinatura',
      authSelfie: 'Autenticação por selfie',
      authHandwritten: 'Assinatura Manuscrita',
      authOfficialDocumentFront: 'Documento oficial frente',
      authOfficialDocumentBack: 'Documento oficial verso',
      authOfficialDocumentFrontBack: 'Documento oficial frente e verso',
      authLiveness: 'Selfie dinâmica',
      facialBiometrics: 'Biometria facial',
      authBiometric: 'Biometria facial - CAF e SERPRO',
      presential: 'Assinatura presencial',
      addressProof: 'Comprovante de residência',
    },
    tooltip: {
      refusalReason: 'Motivo da recusa',
      refusalInfo: 'Exclua e adicione novamente o signatário para que ele tente assinar outra vez',
      blockWhatsappReminder: 'Desabilitado para envio via WhatsApp',
      orderingBlocked: 'Desative a ordenação para editar',
    },
    signer: {
      signAs: 'Assinar como',
      authAs: 'Autenticar usando',
      selfSigner: '(Você)',
      signAllDocuments: 'Assina todos os documentos',
      communicateBy: 'Envio via',
      signatureRefusal: 'Recusa de assinatura',
      permitted: 'habilitada',
      notPermitted: 'desabilitada',
      fullName: 'Nome completo',
      email: 'E-mail',
      cellphone: 'Número do celular',
      cpf: 'CPF',
      birthday: 'Data de nascimento',
      selfie: 'Selfie com documento',
      handwritten: 'Assinatura manuscrita',
      officialDocument: 'Documento oficial',
      facialBiometricsEnabled: 'Biometria facial',
      liveness: 'Selfie dinâmica',
      locationRequired: 'Localização',
      voiceBiometrics: 'Biometria de voz',
      auths: {
        api: 'Api',
        sms: 'Token via SMS',
        whatsapp: 'Token via WhatsApp',
        pix: 'Pix',
        email: 'Token via E-mail',
        icpBrasil: 'Certificado digital',
        officialDocument: 'Documento oficial',
        selfie: 'Selfie com documento',
        liveness: 'Selfie dinâmica',
        handwritten: 'Assinatura manuscrita',
        facialBiometrics: 'Biometria facial',
        voiceBiometrics: 'Biometria de voz',
        biometric: 'Biometria facial - CAF e SERPRO',
        addressProof: 'Comprovante de residência',
        provideEvidence: 'Sem token',
      },
      communicateByOptions: {
        email: 'E-mail',
        whatsapp: 'WhatsApp',
        sms: 'SMS',
      },
      info: {
        icpBrasil: {
          signedWith:
            '{subjectName} {certificateType} assinou com certificado digital ICP-Brasil válido até {validityEnd}.',
          details: 'CPF: {cpf}, Número Serial do Cerficado ICPBR: {serialNumber}',
        },
        more: 'Ver mais',
        less: 'Ver menos',
      },
      notify: {
        send: 'Lembrete enviado!',
      },
      notifyAll: {
        send: 'Enviar lembrete',
        title: 'Enviar lembrete aos signatários',
        description:
          'Você acabou de excluir um signatário que recusou a assinatura. Agora o documento pode ser assinado pelos outros signatários também. Que tal enviar um lembrete para avisar que já é possível assinar o documento?',
      },
      delete: {
        confirm: 'Clique para confirmar',
      },
      pix: 'Autenticação por pix',
      rubric: 'Rubrica eletrônica',
      hasRubric: 'habilitada',
    },
    actions: {
      move: 'Mover',
      new: 'Nova pasta',
      edit: 'Renomear',
      delete: 'Excluir',
      title: 'Documentos',
      permissions: 'Permissões',
      select_all: 'Selecionar tudo',
    },
    status: {
      canceled: 'Cancelado em %{at}',
      closed: 'Finalizado em %{at}',
      running: 'Em processo até %{at}',
    },
    empty: {
      title: 'Adicione e envie documentos para assinatura.',
      description: 'Baixe um {link} e adicione para testar.',
      document_example: 'documento exemplo',
      document_name: 'Contrato de Prestação de Serviço - Exemplo.pdf',
      new_document: 'Adicionar Documento',
      newDocuments: 'Adicionar Documentos',
      empty_folder: 'Pasta vazia.',
      move_document:
        'Mova um documento para esta pasta selecionando um documento e clicando no botão mover.',
    },
    search: {
      title_search: 'Resultado da busca',
      search_not_found: 'Não encontramos resultados para sua busca.',
    },
    signatures: {
      showMore: 'Ver +%{count}',
      showLess: 'Ver menos',
    },
    document: {
      path: 'em Documentos',
    },
  },
  running: 'em processo',
  closed: 'finalizado',
  canceled: 'cancelado',
  flow: {
    empty:
      'Este documento não foi criado a partir de um fluxo por isto não há informações para exibir.',
    deleted: 'Fluxo excluído',
    details: {
      title: 'Detalhes do fluxo',
      short_title: 'Detalhes',
      form: 'Formulário',
      unanswered: 'Não informado',
    },
    forms: {
      form1_filled_at: 'Preenchimento do formulário 1: %{deadline_at}',
      form2_filled_at: 'Preenchimento do formulário 2: %{deadline_at}',
    },
    attachments: {
      title: 'Anexos',
      description: 'Arquivos enviados via formulário.',
      empty: 'Os detalhes e arquivos do fluxo gerado a partir da automação serão exibidos aqui.',
    },
  },
  declinedSignature: 'Assinatura Recusada',
  infoModal: {
    title: 'Motivo da recusa',
    comments: 'Comentário do signatário:',
    description:
      'recusou a assinatura. | recusou a assinatura pelo seguinte motivo: | recusou a assinatura pelos seguintes motivos:',
    reasons: {
      content: 'Conteúdo do documento',
      incorrectPersonalInfo: 'Dados pessoais incorretos',
      selectedAuthentication: 'Forma de autenticação',
      electronicSignatureUse: 'Uso da assinatura eletrônica',
      location: 'Uso da coleta da localização',
      other: 'Outro motivo',
    },
  },
  details: {
    saveButton: 'Salvar alterações',
    help: {
      text: 'Utilize os detalhes para otimizar a gestão de seus documentos, definindo prazos e incluindo observações. Estas informações serão exibidas apenas para os operadores e não farão parte do documento. {0}',
      link: 'Veja exemplos de uso',
    },
    validityDate: {
      placeholder: 'Selecione a data',
      label: 'Final da vigência',
    },
    detailTextArea: {
      label: 'Observação',
      placeholder: 'Digite uma observação',
    },
    message: {
      success: 'Salvo com sucesso',
      error: 'Ocorreu um erro',
    },
    event: {
      button: 'Adicionar detalhe',
      form: {
        name: {
          placeholder: 'Digite o título do detalhe',
          label: 'Título',
        },
        date: {
          label: 'Data',
          placeholder: 'DD/MM/AAAA',
        },
        kind: {
          label: 'Tipo de campo',
          placeholder: 'Data',
          items: {
            date: 'Data',
            text: 'Texto',
          },
        },
        description: {
          label: 'Descrição',
        },
      },
    },
  },
};

export default document;
