const chat = {
  error: 'Houve um erro ao enviar: ',
  message: {
    placeholder: 'Fale com o Assistente',
  },
  leave: {
    mustChatLeave: 'Deseja sair da conversa?',
    warnChatLeave: 'Se você sair, você perderá a conversa atual com o Assistente de automação.',
    cancelLeave: 'Continuar conversa',
    template: 'Se você sair, as alterações feitas no modelo de documento não serão salvas.',
  },
  templateEditTitle: 'Editando Modelo',
  messages: {
    previewFormTemporaryMessage:
      'Seu formulário foi gerado com sucesso. Clique no link abaixo para visualizá-lo.',
    flowSettingsTemporaryMessage:
      'Para facilitar, adicionamos essas configurações nos documentos que serão gerados a partir desse fluxo.',
  },
  flowSettings: {
    flowName: 'Nome do fluxo',
    deadline: 'Data limite',
    daysAfterDocumentCreation:
      '{0} dia após a criação do documento|{0} dias após a criação do documento',
    autoReminders: 'Lembretes automáticos',
    eachDays: 'a cada {0} dia|a cada {0} dias',
    language: 'Idioma',
    portuguese: 'Português',
    english: 'Inglês',
    documentsFinish: 'Finalização dos documentos',
    autoDocumentFinish: 'Após a última assinatura do documento',
    manualDocumentFinish: 'Na data limite do documento',
  },
  signersTable: {
    fetchError: 'Erro ao carregar signatários',
    nameContactOrAuth: 'Nome, contato ou autenticação',
    emptySearch: 'Nenhum signatário encontrado. Que tal fazer uma nova busca?',
    signers: 'Signatários',
    name: 'Nome',
    contact: 'Contato',
    auth: 'Autenticação',
    signerFromForm: 'Signatário via formulário',
    contactFromEmail: 'Contato via E-mail',
    contactFromWhatsapp: 'Contato via WhatsApp',
  },
  actions: {
    generateModel: 'Gerar modelo',
    generatePreview: 'Gerar preview',
    attachment: 'Anexar',
    seeModel: 'Ver modelos',
    showPreview: 'Visualizar modelo',
    showAuths: 'Selecionar autenticações',
    selectedAuths: 'Autenticação selecionada: {auths}. | Autenticações selecionadas: {auths}.',
    continueChatFlow: 'Continuar fluxo',
    newSigner: 'Adicionar signatário',
    activateFlow: 'Ativar fluxo',
    editTemplate: '@:template.setup.editTemplate',
    downloadPreview: 'Baixar modelo',
    copyFlowLink: 'Abrir link deste fluxo',
    editSigners: 'Editar signatários',
  },
};

export default chat;
