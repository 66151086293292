<template>
  <div :class="$style.copyGroup">
    <span :class="[label && $style.label]">
      {{ label }}
    </span>
    <div :class="$style.copyText">
      <XTextInput
        ref="copyAssertionEndpoint"
        :value="value"
        :class="$style.input"
        data-testid="copyText"
        :message-type="info ? 'info' : 'error'"
        :message="message"
        readonly
      />
      <XButton
        :class="$style.button"
        size="sm"
        data-testid="copyButton"
        design="outlined"
        @click="copyText(value)"
      >
        <span> {{ $t('accountModule.saml.provider.copy') }} </span>
        <XIcon
          icon="copy"
          right
        />
      </XButton>
    </div>
  </div>
</template>

<script>
import { useToast } from '@widget/hooks';
import { useI18n } from '@/locales';

export default {
  name: 'BaseCopyInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    info: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();

    const copyText = async (value) => {
      const clipboardData = window.clipboardData || navigator.clipboard;
      await clipboardData.writeText(value);
      toast.success(t('accountModule.saml.provider.copied'));
    };

    return { copyText };
  },
};
</script>

<style lang="scss" module>
.copyGroup {
  width: 100%;

  .label {
    display: block;
    margin-bottom: var(--space-small-xx);
  }

  > .copyText {
    display: flex;
    flex-direction: row;
    width: 100%;

    .input,
    input {
      width: 100%;
    }

    > .button {
      min-width: fit-content;
      margin-left: var(--space-small-x);
    }
  }
}

@include breakpoint('extra-small') {
  .copyGroup {
    > .copyText {
      display: flex;
      flex-direction: column;

      > .button {
        margin: var(--space-small-xx) 0 0 0;
      }
    }
  }
}
</style>
