<script setup>
import { useStore } from '@/store';
import { useRoute, useRouter } from '@base/hooks';
import { isEqual } from 'lodash';
import { computed, onMounted, ref } from 'vue';

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const route = useRoute();
const router = useRouter();

const filterValue = ref([]);

const flowsFilter = computed(() => store.getters['flowProcess/getFlowsFilter']);
const filterItems = computed(() => {
  return flowsFilter.value.map(({ name, id }) => ({ text: name, value: id }));
});
const flowFormIdParam = computed(() => route.query.flow_form_id || route.query.flowFormId || []);
const flowFormIdNumber = computed(() => flowFormIdParam.value.map((item) => +item));
const hasFilterSelected = computed(() => filterValue.value.length > 0);
const filterHasNotChanged = computed(() => isEqual(flowFormIdNumber.value, filterValue.value));

const updateFilterRouter = () => {
  router.push({ query: { flowFormId: filterValue.value } });
};

const clearFilter = () => {
  filterValue.value = [];
  if (flowFormIdParam.value.length > 0) {
    router.push({ query: {} });
  }
};

onMounted(() => {
  filterValue.value = flowFormIdNumber.value;
});
</script>

<template>
  <header :class="$style.header">
    <div :class="$style.contentTitle">
      <h1
        :class="$style.title"
        data-testid="pageTitle"
      >
        {{ $t('compliance.header.title') }}
      </h1>
      <XLink
        to="https://ajuda.clicksign.com/article/324-como-definir-um-processo-de-aprovacao-em-meu-fluxo"
        target="_blank"
        data-ga-event="link-help-approval-form-answers-click"
      >
        <XIcon icon="question-circle" />

        {{ $t('compliance.header.help') }}
      </XLink>
    </div>
    <XAlert
      :class="$style.alert"
      type="info"
      icon="info-circle"
      full-width
    >
      {{ $t('compliance.header.alert') }}
    </XAlert>
    <XSkeletonLoader
      v-if="loading"
      type="rect"
      rounded
      width="100%"
      height="85px"
      data-testid="skeletonLoader"
    />
    <div
      v-else
      :class="$style.filterContainer"
    >
      <XFormField
        :label="$t('compliance.header.filter.label')"
        flexbile
      >
        <div :class="$style.filter">
          <XSelect
            v-model="filterValue"
            multiple
            full-width
            :disabled="!flowsFilter.length"
            :placeholder="$t('compliance.header.filter.placeholder')"
            :items="filterItems"
            data-testid="filterFlow"
          />
        </div>
        <XButton
          design="link"
          size="sm"
          :disabled="!hasFilterSelected"
          data-testid="clearFilterButton"
          @click="clearFilter"
        >
          {{ $t('compliance.header.filter.clear') }}
        </XButton>
        <XButton
          design="outlined"
          size="sm"
          :disabled="filterHasNotChanged"
          data-testid="updateFilterButton"
          @click="updateFilterRouter"
        >
          {{ $t('compliance.header.filter.button') }}
        </XButton>
      </XFormField>
    </div>
  </header>
</template>

<style lang="scss" module>
.header {
  margin-bottom: var(--space-medium);

  .alert {
    margin-bottom: var(--space-regular);
  }

  .contentTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-regular);
    gap: var(--space-small-xx);

    .title {
      margin: 0;
      color: var(--color-brand-primary-600);
    }
  }

  .filterContainer {
    padding: var(--space-small-xx);
    background-color: var(--color-neutral-50);

    .filter {
      width: 303px;
    }
  }
}
</style>
