export default {
  SET_LINKS: (state, payload) => {
    state.links = payload;
  },
  SET_CONSUMPTION_REPORT: (state, payload) => {
    state.consumptionReport = [...payload];
  },
  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload;
  },
  SET_CONSUMPTION_REPORT_COUNTERS: (state, payload) => {
    state.consumptionReportsCounters = payload;
  },
  SET_CONSUMPTION_REPORT_FILTERS: (state, payload) => {
    state.consumptionReportsFilters.filters = { ...payload };
  },
  SET_CONSUMPTION_REPORT_URL: (state, payload) => {
    state.consumptionReportUrl = payload;
  },
  SET_LIMIT_FOR_ALL_REPORTS: (state) => {
    state.consumptionReportsFilters.filters = {
      ...state.consumptionReportsFilters.filters,
      limit: state.consumptionReportsCounters.total,
    };
  },
  SET_CONSUMPTION_REPORT_PAGINATION: (state, payload) => {
    state.consumptionReportPagination = payload;
  },
};
