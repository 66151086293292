export default {
  sign: 'Assinar',
  signature: 'Assinatura',
  document: 'Documento',
  next: 'Avançar',
  nextWithoutPosition: 'Avançar sem posicionar',
  sendWithoutPosition: 'Enviar sem posicionar',
  position: 'Posicionar',
  signers: 'Signatários',
  select: 'Selecione',
  firstLineTooltip: 'Agora você pode posicionar campos de assinatura no documento.',
  secondLineTooltip: 'Selecione um signatário para começar.',
  signTooltip:
    'Escolha entre assinatura ou rubrica e clique no local desejado no documento para posicionar o campo.',
  titleSignTooltip: 'Defina e posicione o campo',
  titleSignerTooltip: 'Posicionar assinaturas',
  rubric: 'Rubrica',
  addRubric: 'Rubricar',
  mobileWarning: {
    title: 'Continue pelo computador para posicionar',
    continueOnDesktop:
      'Caso deseje definir o local da assinatura ou rubrica no documento, continue pelo computador.',
    avaliableOn: 'Este envio estará disponível em ',
    draftLink: 'Rascunhos.',
    continueWithoutPositioning: 'Avançar sem posicionar',
  },
  saveError: 'Ocorreu um erro ao salvar os posicionamentos!',
  inAllPages: 'Em todas as páginas',
  untilSecondLastPage: 'Em todas as páginas, exceto na última',
  replicate: 'Replicar',
  replicateError: 'Não há mais páginas para replicar o campo',
  replicateTitle: 'Replicar {0} de "{1}"',
  addHandwritten: 'Autenticação Assinatura Manuscrita adicionada ao signatário {0}',
  overlapWarning: 'Não é possível posicionar sobre uma assinatura ou rubrica existente',
  overlapInPageWarning:
    'Não foi possível posicionar na página {0} sobre uma assinatura ou rubrica existente',
};
