import VueI18n from 'vue-i18n';
import Vue from 'vue';

import enUs from './en_US';
import ptBr from './pt_BR';
import vivo from './vivo';

Vue.use(VueI18n);

const messages = {
  'en-US': enUs.messages,
  'pt-BR': ptBr.messages,
  'vivo-pt-BR': vivo.messages,
};

const dateTimeFormats = {
  'en-US': enUs.dateTimeFormats,
  'pt-BR': ptBr.dateTimeFormats,
};

const i18n = new VueI18n({
  locale: 'pt-BR',
  fallbackLocale: 'pt-BR',
  messages,
  dateTimeFormats,
});

export default i18n;

export const useI18n = () => ({
  t: (key, values, locale) => i18n.t(key, locale, values),
  tc: (key, choice, values, locale) => i18n.tc(key, choice, locale, values),
  te: (key, locale) => i18n.te(key, locale),
  d: (value, key, locale) => i18n.d(value, key, locale),
  n: (value, format, locale) => i18n.n(value, format, locale),
  setLanguage: (locale) => {
    i18n.locale = locale;
  },
});
