/**
 * @see {@link https://www.notion.so/clicksign/87af42f7dff64161bb71eb56d03de7c7 Tabela de permissionamento}
 * */
export const FEATURES = {
  ADDITIONAL_AUTH: 'additional_auth',
  ACCOUNT_CANCELLATION: 'account_cancellation',
  FORM_FLOW: 'flow_enabled',
  SPREADSHEET_FLOW: 'spreadsheet_flow_enabled',
  SAML_LOGIN: 'saml_login',
  INTEGRATION_FEATURES: 'integration_features',
  AUTHENTICATE_BY_SELFIE_WITH_DOCUMENT: 'authenticate_by_selfie_with_document',
  DYNAMIC_SELFIE_AUTHENTICATION: 'dynamic_selfie_authentication',
  BIOMETRICS_AUTHENTICATION: 'biometrics_authentication',
  ADDITIONAL_DOCUMENT_AUTHENTICATION: 'additional_document_authentication',
  CUSTOMIZATION_OF_LOGOS_AND_COLORS: 'customization_of_logos_and_colors',
  COMMUNICATE_BY_WHATSAPP: 'communicate_by_whatsapp',
  SEND_MULTIPLE_DOCUMENTS: 'send_multiple_documents',
  AUDIT: 'audit',
  TEMPLATES: 'templates_enabled',
  BATCH_DOWNLOAD: 'batch_download',
  CUSTOM_SENDER: 'custom_sender',
  WHATSAPP_ACCEPTANCE: 'acceptance_term_by_whatsapp',
  PRESENTIAL_SIGNATURE: 'presential_signature',
};
