const refusalSignature = {
  refusalFeedback: {
    title: 'Documento recusado',
    subtitle: 'Enviamos sua resposta ao remetente do documento.',
  },
  refusalModal: {
    title: 'Recusar assinatura',
    report: 'Reportar um erro',
    description: 'Recuse a assinatura para informar o problema ao remetente',
    select: 'Motivo da Recusa',
    textareaLabel: 'Comente aqui com suas palavras',
    textareaPlaceholder: 'Não quero assinar porque...',
    checkbox: 'Entendo que esta ação é definitiva',
    checkboxVivo: 'Estou ciente que essa ação invalida a proposta enviada',
    button: 'Recusar',
    selectItems: {
      content: 'Conteúdo do documento',
      userData: 'Dados pessoais incorretos',
      authType: 'Forma de autenticação',
      signature: 'Uso da assinatura eletrônica',
      location: 'Permissão de localização',
      other: 'Outro motivo',
    },
    authsPlaceholder: 'Especifique a autenticação',
    auths: {
      api: 'Api',
      email: 'Token via e-mail',
      pix: 'Pix',
      icpBrasil: 'Certificado digital',
      sms: 'SMS',
      whatsapp: 'Token via WhatsApp',
      selfie: 'Selfie com documento',
      handwritten: 'Assinatura manuscrita',
      officialDocument: 'Documento oficial',
      liveness: 'Selfie dinâmica',
      facialBiometrics: 'Biometria facial',
      locationRequired: 'Localização',
      voiceBiometrics: 'Biometria de voz',
    },
    form: {
      error: 'Ocorreu um erro ao enviar o formulário',
      success: 'Formulário enviado com sucesso',
    },
    refusableSelectedDocuments: 'Você pode recusar {refusable} de {selected} documentos.',
    seeDocuments: 'Veja quais',
  },
  options: {
    download: 'Baixar documento original',
    refusal: 'Recusar assinatura|Recusar assinaturas',
  },
};

export default refusalSignature;
