import { axios } from '@/plugins';

const fetchFormFlow = async ({ commit }, url) => {
  const {
    data: { flow },
  } = await axios.get(url);

  commit('SET_FORM_FLOW', flow);
};

const updateFlowTemplate = async ({ getters }, payload) => {
  await axios.patch(getters.getFormFlow.template.links.update, payload);
};

const fetchListVariables = async ({ getters, commit }) => {
  let status = 204;
  let data;

  while (status === 204) {
    try {
      const response = await axios.get(getters.getFormFlow.links.listVariables);
      data = response.data;
      status = response.status;
    } catch (error) {
      throw error;
    }

    if (status === 204) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  commit('SET_LIST_VARIABLES', data.formBuildersFields);
};

const fetchSetupFormBuilders = async ({ getters, commit }, { page, name }) => {
  commit('SET_FORM_BUILDERS_LOADING', page === 1);
  const { data } = await axios.get(getters.getLinks.formBuilders, { params: { page, name } });
  const forms = page === 1 ? data.forms : [...getters.getFormBuilders, ...data.forms];

  commit('SET_FORM_BUILDERS_LOADING', false);
  commit('SET_FORM_BUILDERS', forms);
  commit('SET_FORM_BUILDERS_PAGINATION', data.pagination);
};

const fetchSetupTemplates = async ({ getters, commit }, { page, name }) => {
  commit('SET_TEMPLATES_LOADING', page === 1);
  const { data } = await axios.get(getters.getLinks.templates, { params: { page, name } });
  const templates = page === 1 ? data.templates : [...getters.getTemplates, ...data.templates];

  commit('SET_TEMPLATES_LOADING', false);
  commit('SET_TEMPLATES', templates);
  commit('SET_TEMPLATES_PAGINATION', data.pagination);
};

const fetchTemplatePreview = async (_, url) => {
  let status = 204;
  let data;

  while (status === 204) {
    try {
      const response = await axios.get(url);
      data = response.data;
      status = response.status;
    } catch (error) {
      throw error;
    }

    if (status === 204) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  return data.template;
};

const enableFormFlow = async ({ commit, getters }) => {
  try {
    const url = getters.getLinks.show;

    const { data: responseData } = await axios.patch(url, { flow: { enabled: true } });
    const isFlowEnabled = responseData.flow.enabled;

    commit('SET_ENABLED', isFlowEnabled);
    return isFlowEnabled;
  } catch (error) {
    throw error;
  }
};

const createFormFlow = async ({ getters }) => {
  try {
    const url = getters.getLinks.new;

    const { data: responseData } = await axios.post(url, {});

    return { flow: responseData.flow };
  } catch (error) {
    throw error;
  }
};

export default {
  fetchSetupFormBuilders,
  fetchSetupTemplates,
  fetchTemplatePreview,
  fetchFormFlow,
  updateFlowTemplate,
  fetchListVariables,
  enableFormFlow,
  createFormFlow,
};
