const signer = {
  badgeAuths: {
    api: 'Api',
    handwritten: 'Assinatura manuscrita',
    liveness: 'Selfie dinâmica',
    officialDocument: 'Documento oficial',
    selfie: 'Selfie com documento',
    pix: 'Pix',
    icpBrasil: 'Certificado digital',
    facialBiometrics: 'Biometria facial',
    email: 'Token via E-mail',
    whatsapp: 'Token via WhatsApp',
    sms: 'Token via SMS',
    biometric: 'Biometria facial - CAF e SERPRO',
    addressProof: 'Comprovante de endereço',
  },
  status: {
    notSigned: 'Não assinado',
  },
  error: {
    generic: {
      action: 'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o ',
      supportLinkText: 'suporte.',
    },
  },
  details: {
    errorOnLoad: 'Não foi possível obter os detalhes do signatário, tente novamente',
    label: {
      communicateBy: 'Envio via',
      authAs: 'Autenticar usando',
      refusalSignature: 'Recusa de assinatura',
      email: 'E-mail',
      phoneNumber: 'Celular',
      cpf: 'CPF',
      birthday: 'Data de nascimento',
      signedAt: 'Data da assinatura',
      lastSignedAt: 'Data de assinatura dos documentos assinados',
      nameVerification: 'Verificação do nome',
      requiredAuth: 'Autenticação Obrigatória',
    },
    refusalEnabled: 'habilitada',
    information: 'Informações',
    additionalAuths: 'Autenticações adicionais',
    pixVerification: '{0} (E2EId / Banco Central)',
    nameVerification: {
      conferred: 'Nome confere com a base de dados da Receita Federal.',
      cpfNotFound: 'CPF informado não existe na base de dados da Receita Federal.',
      cpfInvalid: 'CPF informado não é válido.',
      divergentName:
        'Nome preenchido diverge do nome cadastrado na base de dados da Receita Federal. ({name})',
      divergentBirthday:
        'Data de nascimento preenchida diverge da data cadastrada na base de dados da Receita Federal. ({birthday})',
      receitaProxyFail: 'Ocorreu um erro durante a consulta de dados na Receita Federal.',
      pending: 'Verificação em andamento.',

      title: 'Verificação do nome',
      cpfEmpty: 'Verificação não realizada.',
      cpfRegularizationPending:
        'CPF informado com regularização pendente na base de dados da Receita Federal.',
      cpfOwnerDeceased: 'Proprietário do CPF falecido segundo a base de dados da Receita Federal.',
      cpfSuspended: 'CPF informado está suspenso segundo a base de dados da Receita Federal.',
      cpfCanceled: 'CPF informado está cancelado segundo a base de dados da Receita Federal.',
      cpfNull:
        'CPF anulado após identificação de fraude cadastral segundo a base de dados da Receita Federal.',
      signerValidatorFail: 'Ocorreu um erro durante a consulta de dados na Receita Federal.',
    },
    icpBrasil:
      '{subjectName} {certificateType} assinou com certificado digital ICP-Brasil válido até {validityEnd}. CPF: {cpf}, Número Serial do Cerficado ICPBR: {serialNumber}',
    auths: {
      api: 'Api',
      handwritten: 'Assinatura manuscrita',
      liveness: 'Selfie dinâmica',
      officialDocument: 'Documento oficial',
      selfie: 'Selfie com documento',
      facialBiometrics: 'Biometria facial',
      icpBrasil: 'Certificado digital',
      pix: 'Pix',
      email: 'Token via E-mail',
      whatsapp: 'Token via WhatsApp',
      sms: 'Token via SMS',
      addressProof: 'Comprovante de endereço',
      biometric: 'Biometria facial - CAF e SERPRO',
      documentscopy: 'Documentoscopia',
    },
  },
  actions: {
    seeMore: 'Ver mais {count} documento | Ver mais {count} documentos',
    seeLess: 'Ver menos',
    sendReminder: 'Enviar lembrete',
    seeDispatchState: 'Andamento do envio',
  },
  singleRemindModal: {
    title: 'Enviar solicitação de assinatura',
    description:
      'O signatário receberá uma solicitação com o link para assinatura. Escreva uma mensagem personalizada, se desejar.',
    whatsappAlert:
      'Para envios via WhatsApp, a solicitação tem custo adicional e não é possível enviar uma mensagem.',
  },
  contactsStep: {
    edit: {
      title: 'Editar contato',
    },
    add: {
      title: 'Adicionar contato',
    },
    delete: {
      title: 'Excluir contato da agenda | Excluir contatos da agenda',
      description:
        'Tem certeza que deseja excluir {0} contato? | Tem certeza que deseja excluir {0} contatos?',
      loading: 'Excluindo {count} de {total}...',
    },
    title: 'Agenda',
    cancel: 'Cancelar',
    confirm: 'Avançar',
    search: 'Buscar contato',
    table: {
      name: 'Nome',
      namePlaceholder: 'Inserir nome do contato',
      email: 'E-mail',
      contact: 'Contato',
      auth: 'Autenticação',
      actions: 'Ações',
      auths: {
        api: 'Api',
        handwritten: 'Assinatura manuscrita',
        liveness: 'Selfie dinâmica',
        officialDocument: 'Documento oficial',
        selfie: 'Selfie com documento',
        pix: 'Pix',
        icpBrasil: 'Certificado digital',
        facialBiometrics: 'Biometria facial',
        email: 'Token via E-mail',
        whatsapp: 'Token via WhatsApp',
        sms: 'Token via SMS',
        biometric: 'Biometria facial - CAF e SERPRO',
        addressProof: 'Comprovante de endereço',
      },
      info: '+Info',
      emptyInfo: 'Não existem informações cadastradas',
      empty: 'Ainda não há nada por aqui. Que tal adicionar seu primeiro contato na agenda?',
      emptySearch: 'Nenhum contato encontrado. Que tal fazer uma nova busca?',
      edit: 'Editar',
      favorite: 'Favorito',
      tabs: {
        all: 'Todos',
        starred: 'Favoritos',
      },
      blockedFunctionalityTooltip: {
        title: '{blockedFunctionality} indisponível para sua conta',
        description1:
          'Adicione este signatário novamente, selecionando outra forma de envio e autenticação.',
        description2: 'Para habilitar {blockedFunctionality}, fale com o suporte.',
        whatsapp: 'WhatsApp',
        sms: 'SMS',
        liveness: 'Autenticação por Selfie Dinâmica',
        facialBiometrics: 'Autenticação por Biometria',
        whatsappWithArticle: 'o WhatsApp',
        smsWithArticle: 'o SMS',
        livenessWithArticle: 'a Autenticação por Selfie Dinâmica',
        facialBiometricsWithArticle: 'a Autenticação por Biometria',
      },
    },
    info: {
      birthday: 'Data de Nascimento',
      documentation: 'CPF',
      phoneNumber: 'Telefone celular',
    },
  },
  infoStep: {
    envelope: {
      title: 'Adicionar novo signatário',
      hasDocumentation: 'Solicitar CPF ao signatário',
      documentationRequired: 'Autenticação selecionada exige CPF',
      emptyState: 'Pelo menos uma autenticação deve ser adicionada.',
      email: {
        label: 'E-mail',
        placeholder: 'Digite o e-mail',
      },
      phoneNumber: {
        label: 'Número do celular',
        placeholder: 'Digite o celular',
      },
      sections: {
        personalInfo: 'Dados adicionais',
      },
      actions: {
        change: 'Alterar',
        add: 'Adicionar',
      },
      authsModal: {
        title: 'Alterar autenticações',
        description: 'Passe o mouse sobre as autenticações para saber mais sobre elas.',
        isAnyAuthSelected: 'Selecione pelo menos uma autenticação',
        whatsappBlocked: '@:signer.premium.upgradePlan para usar o Token via WhatsApp',
        selfieBlocked: '@:signer.premium.upgradePlan para usar a Selfie com documento',
        facialBiometricsBlocked: '@:signer.premium.upgradePlan para usar a Biometria facial',
        livenessBlocked: '@:signer.premium.upgradePlan para usar a Selfie dinâmica',
        smsWarning: 'O envio via SMS gerará um custo adicional.',
        smsWarningHelp: 'Entenda mais sobre o custo do SMS',
        auths: {
          token: 'Token via',
          email: 'E-mail',
          sms: 'SMS',
          pix: 'Pix',
          icpBrasil: 'Certificado Digital',
          whatsapp: 'WhatsApp',
          handwritten: 'Assinatura manuscrita',
          liveness: 'Selfie dinâmica',
          officialDocument: 'Documento oficial',
          facialBiometrics: 'Biometria facial',
          selfie: 'Selfie com documento',
          addressProof: 'Comprovante de endereço',
        },
        authsInfo: {
          link: 'Saiba mais sobre essa autenticação',
          token: {
            title: 'Token de confirmação',
            description:
              'Será enviado um Token (código) através do e-mail, SMS ou WhatsApp a fim de confirmar a identidade de quem assina. O Token funciona como um duplo fator de autenticação.',
          },
          handwritten: {
            title: 'Assinatura manuscrita',
            description:
              'Será solicitado o desenho da assinatura na tela, que será anexado ao final do documento assinado.',
            positionSignDescription:
              'Será solicitado o desenho da assinatura na tela e o resultado ficará anexado ao documento assinado.',
            positionSignComplement:
              'A partir de agora, você também pode escolher onde a assinatura ficará posicionada no documento!',
          },
          selfie: {
            title: 'Selfie com documento',
            description:
              'Será solicitado a quem assina que tire uma foto segurando seu documento de identificação (ex: RG, CNH). A imagem capturada será anexada ao final do documento assinado.',
          },
          addressProof: {
            title: 'Comprovante de endereço',
            description:
              'Será solicitado a quem assina que tire uma foto do comprovante de endereço. A imagem capturada será um anexo do documento final assinado.',
          },
          officialDocument: {
            title: 'Documento oficial',
            description:
              'Será solicitado a quem assina que tire uma foto de seu documento de identificação (ex: RG, CNH). A imagem capturada será anexada ao final do documento assinado.',
          },
          liveness: {
            title: 'Selfie dinâmica',
            description:
              'Será solicitado a quem assina que tire uma foto de seu rosto naquele momento.',
            complement:
              'A imagem passará por uma validação (prova de vida) a fim de confirmar a existência de uma pessoa real na assinatura do documento. A imagem capturada será anexada ao final do documento assinado.',
          },
          pix: {
            title: 'Pix',
            description:
              'Será solicitado a quem assina uma transferência simbólica (Pix) no valor de R$0,01 (um centavo) a partir de uma conta bancária vinculada ao próprio CPF.',
            complement: 'Isso permite comparar o CPF informado e o cadastrado no Banco Central.',
          },
          facialBiometrics: {
            title: 'Biometria facial',
            description:
              'Será solicitado a quem assina que tire uma foto de seu documento de identificação (ex: RG, CNH) e rosto.',
            complement:
              'A imagem do rosto será comparada à foto do documento (face match). As imagens capturadas serão anexadas ao final do documento assinado.',
          },
          icpBrasil: {
            title: 'Certificado Digital',
            description:
              'O signatário precisa ter um certificado A1 ou A3 emitidos em seu CPF para assinar. Não é possível usar outras autenticações com o certificado.',
            complement:
              'Caso deseje que o documento assinado seja gerado no formato PDF/A, entre em contato com o suporte antes de enviar o documento para assinatura.',
          },
        },
      },
    },
    title: 'Signatário novo',
    cancel: 'Cancelar',
    confirm: 'Avançar',
    fullName: 'Nome completo',
    cpf: 'CPF',
    birthday: 'Data de nascimento',
    signerHasCpf: 'Signatário possui CPF',
    isForeigner: 'Este signatário é estrangeiro',
    signerNotHasCpf: 'Não possui CPF',
    email: {
      label: 'E-mail',
      placeholder: 'Digite o e-mail do signatário',
    },
    auth: {
      label: 'Obrigatórias',
      labelOld: 'Autenticação obrigatória',
      placeholder: 'Selecione como o signatário será autenticado',
    },
    communicateBy: {
      label: 'Enviar documento para assinatura via',
      placeholder: 'Selecione...',
      warning: 'O envio via WhatsApp gerará um custo adicional.',
      warningHelp: 'Entenda mais sobre o custo do WhatsApp',
    },
    phoneNumber: {
      label: 'Número do celular',
      placeholder: '( ) __ ____-____',
    },
    signAs: {
      label: 'Assinar como',
      placeholder: 'Selecione como o signatário deverá assinar',
    },
    sections: {
      communication: 'Envio',
      auths: 'Autenticações',
      personalInfo: 'Dados pessoais',
      auth: 'Autenticação',
      sign: 'Assinatura',
    },
    additionalAuths: {
      title: 'Autenticações adicionais',
      label: 'Adicionais',
      tooltip: 'Ao escolher, essa autenticação se torna obrigatória para o signatário também.',
      helpLink: 'Para quê servem?',
    },
    message: {
      description: 'Se a mensagem for respondida, será encaminhada para o seu e-mail',
    },
    description: {
      label: 'Digite um título para identificar esse signatário',
      placeholder: 'Exemplo: Diretor',
    },
    whatsappSignature: {
      label: 'Assinatura via WhatsApp',
      enabled: 'Assinatura via WhatsApp ativada.',
      disabled: 'Assinatura via WhatsApp desativada.',
      helpLink: 'Veja como funciona',
    },
    whatsappFormField: {
      emptyList: 'Não há telefones para selecionar',
    },
    formField: {
      label: 'E-mail do signatário',
      emptyList: 'Não há emails para selecionar',
      tooltip: 'Selecione o título do signatário que deverá receber o documento para assinar.',
    },
    saveAsContact: {
      label: 'Salvar na agenda',
    },
    formSigner: {
      title: 'Adicionar signatário do formulário',
      description:
        'Selecione esta opção quando o e-mail e o WhatsApp do signatário forem provenientes do formulário.',
    },
    spreadsheetSigner: {
      title: 'Adicionar signatário da planilha',
      description: 'Os dados desse signatário serão informados pela planilha.',
    },
    icpBrasilEnabled: {
      info: 'Autenticações adicionais indisponíveis para Certificado Digital',
      text: 'A assinatura com Certificado Digital só será válida se o CPF do certificado e do signatário forem iguais.',
    },
  },
  premium: {
    text: 'Libere mais funcionalidades.',
    action: 'Faça um upgrade.',
    upgradePlan: 'Faça um upgrade de plano',
  },
  signAsStep: {
    title: 'Assinar como',
    cancel: 'Cancelar',
    confirm: 'Adicionar',
    label: 'Assinar como',
    refusal: 'Permitir recusar a assinatura',
    rubric: 'Confirmação de leitura',
    placeholder: 'Selecione como o signatário deverá assinar',
    authAs: 'Autenticar usando',
    error:
      '{signAs} já está sendo usada para esse signatário | {signAs} já estão sendo usadas para esse signatário',
    filterPlaceholder: 'Pesquise pelo tipo de assinatura',
  },
  messageStep: {
    sequenceEnabled: {
      title: 'Mensagem e envio',
      cancel: 'Cancelar',
      confirm: 'Agendar envio',
    },
    title: 'Mensagem e envio',
    cancel: 'Cancelar',
    confirm: 'Enviar documento',
    signer: 'Signatário',
    signAs: 'Forma de assinatura',
    message: {
      description: 'Se a mensagem for respondida, será encaminhada para o seu e-mail',
    },
    alert: {
      whatsappDisable: 'Mensagem desabilitada para assinaturas via WhatsApp',
      whatsappWarning: 'A mensagem abaixo será encaminhada apenas para quem assinar por e-mail',
    },
    signers: 'Signatários',
    abort: {
      title: 'Cancelar envio?',
      sure: 'Tem certeza que deseja cancelar?',
      info: 'Este signatário não será adicionado na lista de assinatura. | Estes signatários não serão adicionados na lista de assinatura.',
      cancel: 'Não, manter',
      confirm: 'Sim, cancelar envio',
    },
  },
  clausesStep: {
    confirm: 'Avançar',
    cancel: 'Cancelar',
    title: 'Rubrica eletrônica',
    document: 'Documento',
    selectedSigners: 'Signatário selecionado: | Signatários selecionados:',
    clause: 'Cláusula',
    signersReminder:
      'O signatário abaixo deverá rubricar todas as cláusulas que você selecionar | Os signatários abaixo deverão rubricar todas as cláusulas que você selecionar',
    label: 'Precisa que quem assina confirme a leitura do documento?',
  },
  communicateBy: {
    email: 'E-mail',
    whatsapp: 'WhatsApp',
    sms: 'SMS',
  },
  authsKind: {
    email: 'Token via e-mail',
    sms: 'Token via SMS',
    pix: 'Pix (CPF obrigatório)',
    icpBrasil: 'Certificado Digital (CPF obrigatório)',
    whatsapp: 'Token via WhatsApp',
    handwritten: 'Assinatura manuscrita',
    liveness: 'Selfie dinâmica',
    officialDocument: 'Documento oficial',
    facialBiometrics: 'Biometria facial (CPF obrigatório)',
    selfie: 'Selfie com documento',
    addressProof: 'Comprovante de endereço',
    biometric: 'Biometria facial - CAF e SERPRO',
  },
  auths: {
    api: 'Api',
    handwritten: 'Assinatura manuscrita',
    liveness: 'Selfie dinâmica',
    officialDocument: 'Documento oficial',
    selfie: 'Selfie com documento',
    email: 'E-mail',
    sms: 'SMS',
    pix: 'Pix',
    icpBrasil: 'Certificado digital',
    whatsapp: 'Whatsapp',
    facialBiometrics: 'Biometria facial (CPF obrigatório)',
    addressProof: 'Comprovante de endereço',
    biometric: 'Biometria facial - CAF e SERPRO',
    documentscopy: 'Documentoscopia',
  },
  signaturesKind: {
    sign: 'Assinar',
    witness: 'Testemunha',
    party: 'Parte',
    intervening: 'Interveniente',
    receipt: 'Assinar para acusar recebimento',
    approve: 'Assinar para aprovar',
    endorser: 'Endossante',
    endorsee: 'Endossatário',
    administrator: 'Administrador',
    guarantor: 'Avalista',
    transferor: 'Cedente',
    transferee: 'Cessionário',
    contractee: 'Contratada',
    contractor: 'Contratante',
    jointDebtor: 'Devedor solidário',
    issuer: 'Emitente',
    manager: 'Gestor',
    buyer: 'Parte compradora',
    seller: 'Parte vendedora',
    attorney: 'Procurador',
    legalRepresentative: 'Representante legal',
    coResponsible: 'Responsável solidário',
    validator: 'Validador',
    surety: 'Fiador',
    lessor: 'Locador',
    lessee: 'Locatário',
    ratify: 'Assinar para homologar',
    employer: 'Empregador',
    employee: 'Empregado',
    consenting: 'Anuente',
    accountant: 'Contador',
    debtor: 'Devedor',
    franchisee: 'Franqueado',
    creditor: 'Credor',
    franchisor: 'Franqueador',
    insured: 'Segurado',
    grantor: 'Outorgante',
    president: 'Presidente',
    partner: 'Sócio',
    comforter: 'Comodante',
    accountHolder: 'Correntista',
    distracted: 'Distratada',
    distracting: 'Distratante',
    lender: 'Mutuante',
    associate: 'Associado',
    broker: 'Corretor',
    bailee: 'Fiel depositário',
    collateralProvider: 'Caucionante',
    pledged: 'Consignado',
    consignee: 'Consignatário',
    lawyer: 'Advogado',
    grantee: 'Outorgado',
    borrower: 'Comodatário',
    guarantorSpouse: 'Cônjuge do fiador',
    legalGuardian: 'Responsável legal',
    director: 'Diretor(a)',
    realEstateBroker: 'Corretor de imóveis',
    insuranceBroker: 'Corretor de seguros',
    licensor: 'Licenciante',
    licensed: 'Licenciada',
    serviceProvider: 'Prestador(a) de serviços',
    secured: 'Afiançado',
    consented: 'Anuído',
    interveningGuarantor: 'Interveniente Garantidor',
    consentingIntervenor: 'Interveniente Anuente',
    surveyor: 'Vistoriador',
    buildingManager: 'Síndico(a)',
    intermediary: 'Intermediário(a)',
    condominiumMember: 'Condômino',
    owner: 'Proprietário(a)',
    resident: 'Morador(a)',
    treasurer: 'Tesoureiro(a)',
    secretary: 'Secretário(a)',
    signedAsSign: 'Assinou',
    signedAsApprove: 'Assinou para aprovar',
    signedAsParty: 'Assinou como parte',
    signedAsWitness: 'Assinou como testemunha',
    signedAsIntervening: 'Assinou como interveniente',
    signedAsReceipt: 'Assinou para acusar recebimento',
    signedAsEndorser: 'Assinou como endossante',
    signedAsEndorsee: 'Assinou como endossatário',
    signedAsAdministrator: 'Assinou como administrador',
    signedAsGuarantor: 'Assinou como avalista',
    signedAsTransferor: 'Assinou como cedente',
    signedAsTransferee: 'Assinou como cessionário',
    signedAsContractee: 'Assinou como contratada',
    signedAsContractor: 'Assinou como contratante',
    signedAsJointDebtor: 'Assinou como devedor solidário',
    signedAsIssuer: 'Assinou como emitente',
    signedAsManager: 'Assinou como gestor',
    signedAsBuyer: 'Assinou como parte compradora',
    signedAsSeller: 'Assinou como parte vendedora',
    signedAsAttorney: 'Assinou como procurador',
    signedAsLegalRepresentative: 'Assinou como representante legal',
    signedAsCoResponsible: 'Assinou como responsável solidário',
    signedAsValidator: 'Assinou como validador',
    signedAsSurety: 'Assinou como fiador',
    signedAsLessor: 'Assinou como locador',
    signedAsLessee: 'Assinou como locatário',
    signedAsRatify: 'Assinou para homologar',
    signedAsEmployer: 'Assinou como empregador',
    signedAsEmployee: 'Assinou como empregado',
    signedAsConsenting: 'Assinou como anuente',
    signedAsAccountant: 'Assinou como contador',
    signedAsDebtor: 'Assinou como devedor',
    signedAsFranchisee: 'Assinou como franqueado',
    signedAsCreditor: 'Assinou como credor',
    signedAsFranchisor: 'Assinou como franqueador',
    signedAsInsured: 'Assinou como segurado',
    signedAsGrantor: 'Assinou como outorgante',
    signedAsPresident: 'Assinou como presidente',
    signedAsPartner: 'Assinou como sócio',
    signedAsComforter: 'Assinou como comodante',
    signedAsAccountHolder: 'Assinou como correntista',
    signedAsDistracted: 'Assinou como distratada',
    signedAsDistracting: 'Assinou como distratante',
    signedAsLender: 'Assinou como mutuante',
    signedAsAssociate: 'Assinou como associado',
    signedAsBroker: 'Assinou como corretor',
    signedAsBailee: 'Assinou como fiel depositário',
    signedAsCollateralProvider: 'Assinou como caucionante',
    signedAsPledged: 'Assinou como consignado',
    signedAsConsignee: 'Assinou como consignatário',
    signedAsLawyer: 'Assinou como advogado',
    signedAsGrantee: 'Assinou como outorgado',
    signedAsBorrower: 'Assinou como comodatário',
    signedAsGuarantorSpouse: 'Assinou como cônjuge do fiador',
    signedAsLegalGuardian: 'Assinou como responsável legal',
    signedAsDirector: 'Assinou como diretor(a)',
    signedAsRealEstateBroker: 'Assinou como corretor de imóveis',
    signedAsInsuranceBroker: 'Assinou como corretor de seguros',
    signedAsLicensor: 'Assinou como licenciante',
    signedAsLicensed: 'Assinou como licenciada',
    signedAsServiceProvider: 'Assinou como prestador(a) de serviços',
    signedAsSecured: 'Assinou como afiançado',
    signedAsConsented: 'Assinou como anuído',
    signedAsInterveningGuarantor: 'Assinou como interveniente garantidor',
    signedAsConsentingIntervenor: 'Assinou como interveniente anuente',
    signedAsSurveyor: 'Assinou como vistoriador',
    signedAsBuildingManager: 'Assinou como síndico(a)',
    signedAsIntermediary: 'Assinou como intermediário(a)',
    signedAsCondominiumMember: 'Assinou como condômino',
    signedAsOwner: 'Assinou como proprietário(a)',
    signedAsResident: 'Assinou como morador(a)',
    signedAsTreasurer: 'Assinou como tesoureiro(a)',
    signedAsSecretary: 'Assinou como secretário(a)',
  },
  documents: {
    refused: 'Assinaturas recusadas',
    pending: 'Pendentes de assinatura',
    signed: 'Assinados',
    canceled: 'Cancelados',
    notSigned: 'Não assinados',
  },
  editSigner: {
    title: 'Editar signatário',
    signAs: 'Assinar como',
  },
  blockedByManager: '(Indisponível na sua conta)',
};

export default signer;
