import { signatureColors } from '@document/constants/positionSign/signature-colors';
import * as PDFJSLib from 'pdfjs-dist/legacy/build/pdf';

export const PAGE_CLASS = '.page';
const DISCOUNT_FROM_MOUSE = 10;

const SCREEN_PPI = PDFJSLib.PixelsPerInch.CSS;
const POINT_PPI = PDFJSLib.PixelsPerInch.PDF;

/**
 * @description this function map those 7 colors based on index if passed index don't exist it
 * restart colors
 * @returns {{bg: String, border: String, text: String}} - Colors to use on element
 */
export function getColorByIndex(index = 0) {
  let internalIndex = index;
  const lastColorIndex = signatureColors.length;

  if (index >= lastColorIndex) {
    const floatingPoint = (index / signatureColors.length) % 1;

    internalIndex = Math.round(floatingPoint * signatureColors.length);
  }

  return signatureColors[internalIndex];
}

/**
 * returns a random number to use as element :ref
 * @returns {Number}
 */
export const getRandomId = () => Math.floor(Math.random() * 16777215);

/**
 * @description This function calculate the position of mouse event relative to a element
 * basically click event is relative to viewport and to get the distance
 * that mouse runs inside an element is necessary to calculate
 * distance between element and mouse click
 * @param {Node} element
 * @param {Event} e - mouse event
 * @returns {{x: Number, y: Number}}
 */
export const getCoordinatesRelativeToElement = (element, e) => {
  const rect = element.getBoundingClientRect();
  const x = e.clientX - rect.left + DISCOUNT_FROM_MOUSE;
  const y = e.clientY - rect.top + DISCOUNT_FROM_MOUSE;

  return { x, y };
};

/**
 *
 * @param {Number} val - Expected value
 * @param {Number} min - Min value
 * @param {Number} max - Max value
 * @returns {Number}
 */
export const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

/**
 * @description this function check if composedPath from mouse event contains PAGE_CLASS element
 * and returns its page
 * @param {Event} e
 * @returns {Node|null}
 */
export const getPdfPage = (e) => {
  const elements = e.composedPath();
  const pdfPage = elements.find((el) => el?.matches?.(`${PAGE_CLASS}`));

  return pdfPage;
};

// TODO: refactor this to a robust way to detect collision
/**
 * @description this function check if has a collision between placeholder element and
 * draggable element do that checking for composedPath from mouse event
 * @param {Event} e
 * @param {Node} dragPlaceHolder
 * @param {String} dragClass
 * @returns {Boolean} - return true/false checking if has collision with draggable or out of bounds
 */
export const canHidePlaceholder = (e, dragClass) => {
  const elements = e.composedPath();
  const hasDraggable = elements.some((el) => el?.matches?.(dragClass));
  const isInbound = !!getPdfPage(e);

  return hasDraggable || !isInbound;
};

/**
 * @description this function returns the right position that the element mighty be to not
 * pass parent limits on drag operation
 * @param {DOMRect} boundRect - The parent element that will limit child movement
 * @param {DOMRect} nodeRect - element coords
 * @param {{x: Number, y: Number}} mouseCoord - mouse coords
 * @param {DOMRect} parentRect - the parent top to use on translate
 * @returns {Array<Number>} - this array represents the x and y
 */
export const boundCalculation = (boundRect, nodeRect, mouseCoord, parentRect) => {
  const { x: mouseX, y: mouseY } = mouseCoord;
  const relativeDistanceY = mouseY - parentRect.top + DISCOUNT_FROM_MOUSE;
  const relativeDistanceX = mouseX - parentRect.left + DISCOUNT_FROM_MOUSE;

  const virtualClientBounds = {
    left: mouseX - parentRect.left - nodeRect.width,
    right: boundRect.right - parentRect.left - nodeRect.width,
    top: mouseY - parentRect.top - nodeRect.height,
    bottom: boundRect.bottom - parentRect.top - nodeRect.height,
  };

  const finalX = clamp(relativeDistanceX, virtualClientBounds.left, virtualClientBounds.right);
  const finalY = clamp(relativeDistanceY, virtualClientBounds.top, virtualClientBounds.bottom);

  return [finalX, finalY];
};

/**
 * @description it is necessary because backend needs this value in pt
 * @see @link https://stackoverflow.com/questions/10855218/conversion-rate-of-pt-em-px-percent-other
 * @param {Number} px
 * @returns {Number} - value in pt unit
 */
export const convertPxToPt = (px) => {
  return Number(((POINT_PPI / SCREEN_PPI) * px).toFixed(2));
};

/**
 * @description it is necessary because frontend needs this value in pt
 * @see @link https://stackoverflow.com/questions/10855218/conversion-rate-of-pt-em-px-percent-other
 * @param {Number} pt
 * @returns {Number} - value in px unit
 */
export const convertPtToPx = (pt) => {
  return Number(((SCREEN_PPI / POINT_PPI) * pt).toFixed(2));
};

/**
 *
 * @param {Number} coordinate - x or y coordinate
 * @param {Number} offset - parent element offset
 * @returns {Number} - an absolute value
 */
export const getCoordinateRelativeToOffset = (coordinate, offset) => Math.abs(coordinate - offset);

/**
 * @description on view is necessary offset and sign position on page
 * @param {Number} coordinate - x or y coordinate
 * @param {Number} offset - parent element offset
 * @returns {Number} - an absolute value
 */
export const getCoordinatePlusOffset = (coordinate, offset) => coordinate + offset;

/**
 * @description increment position with offset and convert to px unit
 * @param {Number} coordinate
 * @param {Number} offset
 * @returns {Number} - A coordinate
 */
export const processCoordinateForFrontend = (coordinate, offset) => {
  const convertedUnit = convertPtToPx(coordinate);
  return getCoordinatePlusOffset(convertedUnit, offset);
};

/**
 *
 * @param {Number} coordinate
 * @param {Number} containerSize
 * @returns {Number} - A fractional number example 0.1 0.2
 */
const getPercentageMovement = (coordinate, containerSize) => {
  return coordinate / containerSize;
};

/**
 * @description decrease position from offset and convert to percentage unit
 * @param {Number} coordinate
 * @param {Number} offset
 * @returns {Number} - A coordinate
 */
export const processCoordinateForBackend = (coordinate, dimension, offset) => {
  const valueWithoutOffset = getCoordinateRelativeToOffset(coordinate, offset);

  return getPercentageMovement(valueWithoutOffset, dimension);
};

/**
 *
 * @param {String} prefix - example: signature
 * @param {String} id - 4e910d26-6d03-11ee-b962-0242ac120002
 * @returns {String}
 */
export const generateTestId = (prefix, id) => `${prefix}-${id.replace(/-/gi, '')}`;

/**
 * @description calculates available space on screen taking in consideration the menus
 * and avoid overflow
 * @param {HTMLElement} boundUp
 * @param {HTMLElement} boundBottom
 * @returns {String} - example: "430px"
 */
export const calculateHeightWithMenu = (boundUpEl, boundBottomEl) => {
  const bottomFromTopMenu = boundUpEl.getBoundingClientRect().top + window.scrollY;
  const heightFromBottomMenu = boundBottomEl.offsetHeight;

  const maxHeight = window.innerHeight - (bottomFromTopMenu + heightFromBottomMenu);

  return `${maxHeight}px`;
};

/**
 * @description set the max value that a signature can be positioned in that case is
 * max page content box top and bottom, after get the min value between user
 * positioned and max value
 * @param {Array<Number>} coordinates - x and y coordinates
 * @param {HTMLElement} clickedPage
 * @param {HTMLElement} signatureElement
 * @returns {Array<Number>} - returns modified x and y coordinates
 */
export const calculateMaxCoordinatePosition = ([x, y], clickedPage, signatureElement) => {
  const leftPage = clickedPage.offsetLeft;
  const signWidth = signatureElement.offsetWidth;
  const pageBoundRight = leftPage + clickedPage.offsetWidth - signWidth;

  const finalX = Math.min(x, pageBoundRight);

  const topPage = clickedPage.offsetTop;
  const signHeight = signatureElement.offsetHeight;
  const pageBoundBottom = topPage + clickedPage.offsetHeight - signHeight;

  const finalY = Math.min(y, pageBoundBottom);

  return [finalX, finalY];
};

const getRectangleFromSeal = (seal) => {
  return {
    x: seal.position.x,
    y: seal.position.y,
    width: seal.width,
    height: seal.height,
  };
};

const hasOverlapBetweenRectangles = (rect1, rect2) =>
  rect1.x < rect2.x + rect2.width &&
  rect1.x + rect1.width > rect2.x &&
  rect1.y < rect2.y + rect2.height &&
  rect1.y + rect1.height > rect2.y;

/**
 * @description this function checks if the seal is overlapping with another seal
 * @param {Seal} seal - The seal to check the overlap.
 * @param {Array<Seal>} allSeals - All seals to check the overlap.
 * @returns {Boolean} - Returns true if the seal is overlapping with another seal, otherwise false.
 */
export const isSealOverlapped = (seal, allSeals) => {
  const sealRect = getRectangleFromSeal(seal);

  const hasOverlap = allSeals.some((sealToCheckOverlap) => {
    if (
      seal.id === sealToCheckOverlap.id ||
      seal.documentKey !== sealToCheckOverlap.documentKey ||
      seal.dataPageNumber !== sealToCheckOverlap.dataPageNumber
    )
      return false;

    return hasOverlapBetweenRectangles(sealRect, getRectangleFromSeal(sealToCheckOverlap));
  });

  return hasOverlap;
};
