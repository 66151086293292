const liveness = {
  carousel: {
    step1: {
      title: 'Hora da foto!',
      text: 'Precisamos tirar uma foto sua (selfie) para garantir sua identidade e manter a segurança da sua compra',
    },
  },
  cafModal: {
    content: {
      success: 'Pronto! Conseguimos capturar sua foto!',
      sdkError:
        'Algo deu errado! Não conseguimos capturar sua foto. Vamos tentar fazer a validação mais uma vez?',
    },
  },
  attemptsError: {
    title: 'Não conseguimos confirmar sua identidade!',
    subtitle:
      'Por isso, não vamos conseguir seguir com a sua solicitação nesse momento. Procure uma de nossas lojas para fazer a confirmação presencialmente.',
  },
  analyzingModal: 'Aguarde! Estamos confirmando sua identidade, falta pouco!',
  verifyError: {
    reproved: {
      title: 'Poxa, não conseguimos confirmar a sua identidade!',
      description: 'Para seguir com o seu pedido, procure uma de nossas lojas.',
    },
    refused: {
      title: 'Poxa, não conseguimos confirmar a sua identidade!',
      description:
        'Por isso, deve ser enviado um novo link para o seu e-mail ou por SMS para uma nova tentativa de assinatura. {0} Procure o vendedor que te atendeu para que ele reenvie o link da Assinatura Digital.',
    },
  },
};

export default liveness;
